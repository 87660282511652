import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import AmexSvg from "../img/card/Amex.svg";
import Discover from "../img/card/Discover.svg";
import MasterCardSvg from "../img/card/MasterCard.svg";
import VisaSvg from "../img/card/Visa.svg";
import { Address } from "./user";

export interface BillingDetails {
  address: Address;
  name: string;
}

export interface Card {
  brand: CardBrands;
  expMonth: number;
  expYear: number;
  funding: CardFunding;
  last4: string;
}

export interface PaymentMethod {
  id: string;
  billingDetails: BillingDetails;
  card: Card;
}

export enum PaymentManagerSetup {
  ADD = "add",
  EDIT = "edit",
  SET_DEFAULT = "setDefault",
}

export enum CardFunding {
  CREDIT = "credit",
  DEBIT = "debit",
  PREPAID = "prepaid",
  UNKNOWN = "unknown",
}

export enum CardBrands {
  AMEX = "amex",
  DINERS = "diners",
  DISCOVER = "discover",
  MASTERCARD = "mastercard",
  VISA = "visa",
  UNKNOWN = "unknown",
}

export enum FieldNames {
  NAME_ON_CARD = "nameOnCard",
  CARD_NUMBER = "cardNumber",
  CVC = "cvc",
  EXPIRY = "expiry",
  ZIP_CODE = "zipCode",
}

export type PaymentFormField =
  | FieldNames.NAME_ON_CARD
  | FieldNames.CARD_NUMBER
  | FieldNames.CVC
  | FieldNames.EXPIRY
  | FieldNames.ZIP_CODE;

export interface PaymentField {
  field?: PaymentFormField;
  value:
    | string
    | StripeCardNumberElementChangeEvent
    | StripeCardCvcElementChangeEvent
    | StripeCardExpiryElementChangeEvent;
  error: {
    message?: string;
    flagged: boolean;
  };
}

export interface PaymentMethodForm {
  nameOnCard: PaymentField;
  cardNumber: PaymentField;
  cvc: PaymentField;
  expiry: PaymentField;
  zipCode: PaymentField;
}

export const CardLogoIndex = new Map<string, string>();
CardLogoIndex.set(CardBrands.AMEX, AmexSvg);
CardLogoIndex.set(CardBrands.DISCOVER, Discover);
CardLogoIndex.set(CardBrands.MASTERCARD, MasterCardSvg);
CardLogoIndex.set(CardBrands.VISA, VisaSvg);
