import {
  AlertBanner,
  Button,
  Feedback,
  FormGroup,
  FormGroupMessage,
  Text,
  TextField,
} from "@runwayhealth/runway-components-react";

export enum ParentComponent {
  THANK_YOU_PAGE = "THANK_YOU_PAGE",
  DASHBOARD = "DASHBOARD",
}

interface ITravelGroupView {
  emailsSentSuccess: boolean;
  emails: string;
  isInvalid: boolean;
  sendingEmails: boolean;
  sendEmails: () => void;
  closeForm: () => void;
  handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setEmailsSentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setEmails: React.Dispatch<React.SetStateAction<string>>;
  parentComponent: ParentComponent;
}

const TravelGroupView = ({
  emailsSentSuccess,
  emails,
  isInvalid,
  sendingEmails,
  sendEmails,
  closeForm,
  handleFieldChange,
  setEmailsSentSuccess,
  setEmails,
  parentComponent,
}: ITravelGroupView) => {
  return (
    <>
      {!emailsSentSuccess && (
        <>
          {parentComponent === ParentComponent.DASHBOARD && (
            <AlertBanner variant="info" isRounded>
              <AlertBanner.Message>
                To protect our customers' information, every traveler is required to submit their
                consultation through their own account.
              </AlertBanner.Message>
            </AlertBanner>
          )}
          <Text>
            Share Runway with your fellow travelers by entering the emails below and clicking the
            "Send" button. We will reach out and help them complete their consultation.
          </Text>
          <FormGroup>
            <TextField
              as="textarea"
              placeholder="Enter emails separated by a comma."
              value={emails}
              onChange={handleFieldChange}
              isInvalid={isInvalid}
            />
            {isInvalid && (
              <FormGroupMessage>
                <Feedback size="sm" isInvalid>
                  Please, verify that you have entered valid email addresses.
                </Feedback>
              </FormGroupMessage>
            )}
          </FormGroup>
        </>
      )}
      {emailsSentSuccess && (
        <>
          <AlertBanner variant="success" isRounded>
            <AlertBanner.Message>
              Instructions have been sent successfully to your travel group! Please remind them to
              check their email to complete their consultation.
            </AlertBanner.Message>
          </AlertBanner>
        </>
      )}
      <hr></hr>
      {!emailsSentSuccess && (
        <div className="case-new">
          <Button
            size="md"
            iconName="ArrowLeft"
            iconPosition="left"
            variant="outline"
            onClick={closeForm}
          >
            Back
          </Button>
          <Button size="md" iconName="ArrowRight" iconPosition="right" onClick={() => sendEmails()}>
            Send
          </Button>
        </div>
      )}
      {emailsSentSuccess && (
        <div className="case-new">
          <Button
            className="done-btn"
            size="md"
            variant="primary"
            isLoading={sendingEmails}
            onClick={() => {
              closeForm ? closeForm() : false;
              setEmailsSentSuccess(false);
              setEmails("");
            }}
          >
            Done
          </Button>
        </div>
      )}
    </>
  );
};

export default TravelGroupView;
