import posthog from "posthog-js";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

import { Case } from "../@types/case";
import { Condition } from "../@types/condition";
import { PricesState, RootState } from "../@types/state";
import { User } from "../@types/user";
import { Ga4DTO } from "../utils/dtos/Ga4DTO";
import { hashEmail } from "../utils/hashEmail.js";

export enum ProductEntryPoint {
  INITIAL = "initial",
  ADDON = "add-on",
}

export enum ItemCategory {
  CONSULTATION = "consultation",
  PRESCRIPTION = "prescription",
}

export type Item = {
  name: string;
  id: string;
  price: number;
  quantity: number;
  category: ItemCategory;
  productEntryPoint: ProductEntryPoint;
};

export interface Ga4Object {
  currency?: string;
  value?: number;
  addonValue?: number;
  coupon?: string | null;
  items?: Item[];
  method?: string;
}

export enum GA4Events {
  PURCHASE = "purchase",
  BEGIN_CHECKOUT = "begin_checkout",
  START_CONSULTATION = "start_consultation",
  ADD_SHIPPING_INFO = "add_shipping_info",
  ADD_PAYMENT_INFO = "add_payment_info",
  LOGIN = "login",
  SIGN_UP = "sign_up",
  ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  VIEW_ITEM = "view_item", // TODO - implement this event
  VIEW_ITEM_LIST = "view_item_list", // TODO - implement this event
}

const getPurchaseData = (items: Item[], totalAmount: number): Ga4Object => ({
  currency: "USD",
  value: totalAmount,
  addonValue: 0,
  coupon: localStorage.getItem("promoCode")?.toUpperCase() ?? undefined,
  items,
});

const useDataLayer = (event: GA4Events) => {
  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);
  const prices = useSelector<RootState, PricesState>((state) => state.price);
  const user = useSelector<RootState, User>((state) => state.user.data);
  const initialConditions = newCase.conditions;
  const upsoldConditions = JSON.parse(sessionStorage.getItem("upsoldConditions") || "[]");

  const visitFeeItem: Item = {
    name: "Consultation",
    id: prices.case.data.id,
    price: (prices.case.data.unit_amount ?? 0) / 100,
    quantity: 1,
    category: ItemCategory.CONSULTATION,
    productEntryPoint: ProductEntryPoint.INITIAL,
  };

  const getProductsFromStore = (conditions: Condition[], entryPoint: ProductEntryPoint): Item[] => {
    if (!conditions || conditions.length === 0) return [];

    const formattedProducts = conditions.map((condition: Condition) => ({
      name: condition.product.name,
      id: condition.product.id,
      price: (condition.price ?? 0) / 100,
      quantity: condition.product.units,
      category: ItemCategory.PRESCRIPTION,
      productEntryPoint: entryPoint,
    }));

    return formattedProducts;
  };
  const initialProducts = getProductsFromStore(initialConditions, ProductEntryPoint.INITIAL);
  const upsoldProducts = getProductsFromStore(upsoldConditions, ProductEntryPoint.ADDON);

  const products = initialProducts.reduce((mergedProductsArray, product) => {
    const matchingItem = upsoldProducts.find((addedProduct) => product.id === addedProduct.id);
    mergedProductsArray.push(matchingItem || product);
    return mergedProductsArray;
  }, [] as Item[]);

  const reportPurchasedProducts = () => {
    const totalAmount = products.reduce((initAmount, product) => {
      return initAmount + product.price;
    }, 0);

    const addOnAmount = upsoldProducts.reduce((initAmount, product) => {
      return initAmount + product.price;
    }, 0);

    const visitFeePriceAmount = (prices.case.data.unit_amount ?? 0) / 100;
    const purchaseData = getPurchaseData(initialProducts, totalAmount + visitFeePriceAmount);

    const dataLayerObject = {
      ...purchaseData,
      items: [...(products ?? []), visitFeeItem],
      addonValue: upsoldConditions.length > 0 ? addOnAmount : 0,
    };

    // Clean promo code from local storage.
    localStorage.removeItem("promoCode");
    triggerEvent(new Ga4DTO(dataLayerObject));
  };

  const reportAccess = () => {
    triggerEvent(
      new Ga4DTO({
        method: "local",
      })
    );
  };

  // Single Item Events

  const reportAddToCart = (condition: Condition) => {
    const affectedItem: Item = {
      name: condition.product.name,
      id: condition.product.id,
      price: (condition.price ?? 0) / 100,
      quantity: condition.product.units,
      category: ItemCategory.PRESCRIPTION,
      productEntryPoint: ProductEntryPoint.INITIAL,
    };

    const itemData = {
      items: [affectedItem],
    };

    triggerEvent(new Ga4DTO(itemData));
  };

  const reportRemoveFromCart = (condition: Condition) => {
    const affectedItem: Item = {
      name: condition.product.name,
      id: condition.product.id,
      price: (condition.price ?? 0) / 100,
      quantity: condition.product.units,
      category: ItemCategory.PRESCRIPTION,
      productEntryPoint: ProductEntryPoint.INITIAL,
    };

    const itemData = {
      items: [affectedItem],
    };

    triggerEvent(new Ga4DTO(itemData));
  };

  const triggerEvent = (ecommerceData: Ga4DTO) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ecommerce: ecommerceData,
        email: hashEmail(user.email) ?? "",
      },
    });
    posthog.capture(event, {
      ecommerce: ecommerceData,
    });
  };

  return { reportPurchasedProducts, reportAccess, reportAddToCart, reportRemoveFromCart };
};

export default useDataLayer;
