import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import ShortInput from "@elements/ShortInput";
import { useTitle } from "@hooks/useTitle";
import { AlertBanner, Button, Feedback, Text, Title } from "@runwayhealth/runway-components-react";
import { ShippingMethods } from "@store/../@types/case";
import { Condition } from "@store/../@types/condition";
import { DateFieldValue, RootState } from "@store/../@types/state";
import { updateShippingMethod } from "@store/cases/casesSlice";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { validateDate } from "@utils/utilsFormvalidation";

const WhenDepart = () => {
  const [date, setDate] = useState<DateFieldValue>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
  });

  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );
  const [validDate, setValidDate] = useState<any>(undefined);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [suggestion, setSuggestion] = useState(false);
  const [isRunwayPlus, setIsRunwayPlus] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useTitle("When do you depart?");

  // Define the current question and reason
  const currentQuestion = "When do you depart?";
  const currentReason = "We ask this to inform your care team.";

  useEffect(() => {
    setIsRunwayPlus(conditions.length === 0 ? true : false);
  }, []);

  const inputsShort = [
    { placeholder: "M", name: "field-1-depart", id: "depart-1", position: 1 },
    { placeholder: "M", name: "field-2-depart", id: "depart-2", position: 2 },
    { placeholder: "D", name: "field-3-depart", id: "depart-3", position: 3 },
    { placeholder: "D", name: "field-4-depart", id: "depart-4", position: 4 },
    { placeholder: "Y", name: "field-5-depart", id: "depart-5", position: 5 },
    { placeholder: "Y", name: "field-6-depart", id: "depart-6", position: 6 },
    { placeholder: "Y", name: "field-7-depart", id: "depart-7", position: 7 },
    { placeholder: "Y", name: "field-8-depart", id: "depart-8", position: 8 },
  ];

  const handleClick = () => {
    if (!dateIsValid()) {
      return;
    }

    const today = dayjs(new Date());
    // Date is valid - update the store and check if it's within a week
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value: validDate.toISOString(),
        important: false,
        questionType: "general",
      })
    );

    const daysOfDifference = validDate.diff(today, "days");

    // if (daysOfDifference <= 4) {
    //   history.push("/delivery");
    // } else {
    //   history.push("/triptype");
    // }
    history.push("/triptype");
  };

  const dateIsValid = () => {
    dispatch(updateShippingMethod(null));

    const passedDate = validateDate(date);

    const today = dayjs(new Date());

    //Validations
    // Validate passed days
    if (!passedDate) {
      setError("Please enter the correct departure date");
      return false;
    }

    // If the user try to get a same-day delivery
    if (passedDate.diff(today, "day", true) < 0) {
      setError("Same-day delivery service is not available in your state yet.");
      return false;
    }

    // 4 days from today
    if (passedDate.diff(today, "hour", true) > 0 && passedDate.diff(today, "day") <= 3) {
      setValidDate(passedDate);
      return true;
    }

    // Between 4 and 10 days
    if (passedDate.diff(today, "day") > 3 && passedDate.diff(today, "day") < 10) {
      if (!isRunwayPlus) {
        //setSuggestion(true);
        dispatch(updateShippingMethod(ShippingMethods.USPS_PRIORITY));
      }
    }

    setValidDate(passedDate);
    return true;
  };

  useEffect(() => {
    setSuggestion(false);

    const dateIsFilled = Object.values(date).every((el) => !!el === true);
    if (dateIsFilled) {
      setIsDisabled(dateIsValid());
    }
  }, [date]);

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && handleClick();
  };

  return (
    <>
      <Header progress={10} />
      <PageConsultation onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="row justify-content-md-center">
            <div className="col-md-9 col-lg-10 col-xl-7">
              <div className="date-inputs">
                <div className="short-inputs-container">
                  {inputsShort.map((item, index) => (
                    <div className={"date-input-map"} key={index}>
                      <ShortInput
                        id={item.id}
                        position={item.position}
                        placeholder={item.placeholder}
                        date={date}
                        setDate={setDate}
                        setError={setError}
                      />
                      {item.position === 2 || item.position === 4 ? "-" : null}
                    </div>
                  ))}
                </div>
                {error && (
                  <div className="rw-page-consultation-feedback-message">
                    <Feedback isInvalid size="sm">
                      {error}
                    </Feedback>
                  </div>
                )}
                {suggestion && !error && (
                  <div className="rw-page-consultation-feedback-message">
                    <AlertBanner isRounded className="depart-banner">
                      <AlertBanner.Message>
                        Given your date of departure, we highly recommend that you select Priority
                        Shipping at checkout so that you can receive your medication on time.
                      </AlertBanner.Message>
                    </AlertBanner>
                  </div>
                )}
              </div>
            </div>
          </div>
        </PageConsultation.Body>

        <div className="row justify-content-md-center">
          <div className="col-md-9 col-lg-10 col-xl-7">
            <Button
              size="lg"
              iconName="ArrowRight"
              isBlock
              onClick={handleClick}
              disabled={!isDisabled}
            >
              Next
            </Button>
          </div>
        </div>
      </PageConsultation>
    </>
  );
};

export default WhenDepart;
