import "../../assets/scss/addressManager.scss";

import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { AddressFormCard, AddressFormGroup } from "@components/elements/ShippingAddress";
import StateSelect from "@components/elements/StateSelect";
import AutocompleteInput from "@elements/AutocompleteInput";
import { yupResolver } from "@hookform/resolvers/yup";
import useAddressSuggestion, { AddressSuggestion } from "@hooks/useAdressSuggestions";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Button,
  Feedback,
  FormGroupMessage,
  Label,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { Address } from "@store/../@types/user";

import { addressInitializerObject, addressSchema } from "./formSchema";

const states = require("../../../pages/WhatState/states.json");

type AddressFormProps = {
  closeForm: () => void;
  onSubmit: (address: Address) => void;
  address?: Address;
  enableCardDecor?: boolean;
};

const AddressForm = ({ closeForm, onSubmit, address, enableCardDecor }: AddressFormProps) => {
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const { getSuggestionResults } = useAddressSuggestion();
  const [debouncedCallApi] = useState(() => debounce(getSuggestionResults, 300));

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<Address>({
    mode: "all",
    resolver: yupResolver(addressSchema),
    defaultValues: addressInitializerObject,
  });

  const lookupAddress = async (query: string) => {
    clearErrors("street");
    setValue("street", query);

    if (query !== "") {
      const addressResults = await debouncedCallApi(query);
      setSuggestions(addressResults ?? []);
    }
  };

  const handleAddressSelection = (chosenAddress: AddressSuggestion) => {
    // Only update if the street line is defined.
    if (chosenAddress.street_line) {
      clearErrors();
      setSuggestions([]);
      reset({
        street: chosenAddress.street_line,
        city: chosenAddress.city,
        state: chosenAddress.state,
        postalCode: chosenAddress.zipcode,
      });
    }
  };

  useEffect(() => {
    if (address) {
      reset(address);
      setValue("default", address.default);
    }
  }, [address, reset, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AddressFormCard $enableCardDecor={enableCardDecor}>
        <Title>Enter your address information</Title>
        {/* Street Line 1 */}
        <AddressFormGroup>
          <Label>Address 1</Label>
          <Controller
            control={control}
            name="street"
            render={() => (
              <AutocompleteInput
                open={suggestions.length > 0}
                options={suggestions}
                onKeyUp={(e: React.SyntheticEvent) =>
                  lookupAddress((e.target as HTMLInputElement).value)
                }
                capturedVariant={(address) => {
                  handleAddressSelection(address as AddressSuggestion);
                }}
                defaultValue={getValues("street")}
              />
            )}
          />
          <FormGroupMessage>
            {errors.street && (
              <Feedback size="sm" isInvalid>
                {errors.street?.message}
              </Feedback>
            )}
          </FormGroupMessage>
        </AddressFormGroup>
        {/* Address 2 */}
        <AddressFormGroup>
          <Label>Address 2</Label>
          <TextField
            id="secondary"
            isInvalid={errors.secondary}
            {...register("secondary")}
          ></TextField>
          <FormGroupMessage>
            {errors.secondary && (
              <Feedback size="sm" isInvalid>
                {errors.secondary?.message}
              </Feedback>
            )}
          </FormGroupMessage>
        </AddressFormGroup>
        {/* City */}
        <AddressFormGroup>
          <Label>City</Label>
          <TextField id="city" isInvalid={errors.city} {...register("city")}></TextField>
          <FormGroupMessage>
            {errors.city && (
              <Feedback size="sm" isInvalid>
                {errors.city?.message}
              </Feedback>
            )}
          </FormGroupMessage>
        </AddressFormGroup>
        <div className="state-zip-group">
          <AddressFormGroup className="state-select">
            <Label className="state-label">State</Label>
            <Controller
              control={control}
              name="state"
              render={({ field: { name, onBlur } }) => (
                <StateSelect
                  name={name}
                  value={getValues("state")}
                  options={states}
                  onBlur={onBlur}
                  onChange={(stateAbbrv: string) => {
                    setValue("state", stateAbbrv);
                    clearErrors("state");
                  }}
                />
              )}
            />
            <FormGroupMessage className="rw-checkbox-feedback">
              {errors.state && (
                <Feedback size="sm" isInvalid>
                  {errors.state?.message}
                </Feedback>
              )}
            </FormGroupMessage>
          </AddressFormGroup>
          <AddressFormGroup>
            <Label>Zip Code</Label>
            <TextField
              id="postalCode"
              isInvalid={errors.postalCode}
              {...register("postalCode")}
            ></TextField>
            <FormGroupMessage>
              {errors.postalCode && (
                <Feedback size="sm" isInvalid>
                  {errors.postalCode?.message}
                </Feedback>
              )}
            </FormGroupMessage>
          </AddressFormGroup>
        </div>
        <AddressFormGroup>
          <Controller
            control={control}
            name="default"
            render={({ field }) => (
              <>
                <Checkbox color="default" checked={field.value} onChange={field.onChange} />
                <Text as="span" size="sm" noMarginBottom>
                  Set as default shipping address
                </Text>
              </>
            )}
          />
        </AddressFormGroup>
        <div className="payment-form-button-wrapper">
          <Button size="lg" variant="outline" onClick={closeForm}>
            Cancel
          </Button>
          <Button type="submit" size="lg">
            Save
          </Button>
        </div>
      </AddressFormCard>
    </form>
  );
};

export default AddressForm;
