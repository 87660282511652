import { lazy, Suspense, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PresentationType } from "@components/Cart";
import icon from "@img/miniLogo.svg";
import {
  AlertBanner,
  AlertBannerMessage,
  Button,
  Card,
  Title,
} from "@runwayhealth/runway-components-react";
import { Condition, ConditionTypes } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";
import { clearQuestions } from "@store/questions/questionsSlice";

// Lazy components
const Cart = lazy(() => import("@components/Cart/index"));

interface INewCaseForm {
  isOpen: boolean;
  closeForm: () => void;
  openReferralForm: () => void;
}

const NewCaseForm = ({ isOpen, closeForm, openReferralForm }: INewCaseForm) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  ).map((condition) => condition.product.name);
  const [includesTyphoid, setIncludesTyphoid] = useState<boolean>(false);

  useEffect(() => {
    setIncludesTyphoid(items.includes(ConditionTypes.TYPHOID_FEVER));
  }, [items]);

  const startConsultation = () => {
    // Initializes questionnaire map.
    sessionStorage.setItem("finished_consultations", JSON.stringify([]));
    // Initializes question fields.
    dispatch(clearQuestions());
    history.push("/welcome-back");
  };

  return (
    <>
      {isOpen && (
        <Card>
          <Container>
            <Row>
              <Col xs={12} sm={12} lg={8}>
                <Title>New consultation</Title>
              </Col>
              <Col>
                <div className="case-new-subtitle">
                  <img width={20} src={icon} />
                  <h4>Order summary below</h4>
                </div>
              </Col>
            </Row>
          </Container>
          <hr></hr>
          {/* {!includesTyphoid && (
            <AlertBanner variant="warning" isRounded>
              <AlertBannerMessage>
                <div className="referral-form-link" onClick={openReferralForm}>
                  Is this consultation for someone else?
                </div>
              </AlertBannerMessage>
            </AlertBanner>
          )} */}
          <Suspense fallback={<div>Loading...</div>}>
            <Cart
              items={items}
              country={""}
              presentationType={PresentationType.DASHBOARD}
              includesTyphoid={includesTyphoid}
            />
          </Suspense>
          <hr></hr>
          <div className="case-new-execute-btns">
            <Button
              size="md"
              iconName="ArrowLeft"
              iconPosition="left"
              variant="outline"
              onClick={closeForm}
            >
              Cancel
            </Button>
            <Button
              size="md"
              iconName="ArrowRight"
              iconPosition="right"
              onClick={() => startConsultation()}
            >
              Start consultation
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default NewCaseForm;
