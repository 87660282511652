import update from "immutability-helper";

import { createSlice } from "@reduxjs/toolkit";
import { PaymentMethodForm } from "@store/../@types/billing";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

const paymentMethodForm: PaymentMethodForm = {
  nameOnCard: {
    value: "",
    error: {
      message: "",
      flagged: false,
    },
  },
  cardNumber: {
    value: {} as StripeCardNumberElementChangeEvent,
    error: {
      message: "",
      flagged: false,
    },
  },
  cvc: {
    value: {} as StripeCardCvcElementChangeEvent,
    error: {
      message: "",
      flagged: false,
    },
  },
  expiry: {
    value: {} as StripeCardExpiryElementChangeEvent,
    error: {
      message: "",
      flagged: false,
    },
  },
  zipCode: {
    value: "",
    error: {
      message: "",
      flagged: false,
    },
  },
};

export const paymentMethodFormSlice = createSlice({
  name: "paymentMethodForm",
  initialState: paymentMethodForm,
  reducers: {
    updateNameOnCard: (state, action) => {
      if (action.payload.nameOnCard) {
        return update(state, {
          nameOnCard: {
            value: {
              $set: action.payload.nameOnCard,
            },
          },
        });
      }

      if (action.payload.error) {
        return update(state, {
          nameOnCard: {
            error: {
              message: {
                $set: action.payload.error.message,
              },
              flagged: {
                $set: action.payload.error.flagged,
              },
            },
          },
        });
      }

      return state;
    },
    updateCardNumber: (state, action) => {
      if (action.payload.cardNumber) {
        return update(state, {
          cardNumber: {
            value: {
              $set: action.payload.cardNumber,
            },
          },
        });
      }

      if (action.payload.error) {
        return update(state, {
          cardNumber: {
            error: {
              message: {
                $set: action.payload.error.message,
              },
              flagged: {
                $set: action.payload.error.flagged,
              },
            },
          },
        });
      }

      return state;
    },
    updateExpiry: (state, action) => {
      if (action.payload.expiry) {
        return update(state, {
          expiry: {
            value: {
              $set: action.payload.expiry,
            },
          },
        });
      }

      if (action.payload.error) {
        return update(state, {
          expiry: {
            error: {
              message: {
                $set: action.payload.error.message,
              },
              flagged: {
                $set: action.payload.error.flagged,
              },
            },
          },
        });
      }

      return state;
    },
    updateCvc: (state, action) => {
      if (action.payload.cvc) {
        return update(state, {
          cvc: {
            value: {
              $set: action.payload.cvc,
            },
          },
        });
      }

      if (action.payload.error) {
        return update(state, {
          cvc: {
            error: {
              message: {
                $set: action.payload.error.message,
              },
              flagged: {
                $set: action.payload.error.flagged,
              },
            },
          },
        });
      }

      return state;
    },
    updateZip: (state, action) => {
      if (action.payload.zipCode) {
        return update(state, {
          zipCode: {
            value: {
              $set: action.payload.zipCode,
            },
          },
        });
      }

      if (action.payload.error) {
        return update(state, {
          zipCode: {
            error: {
              message: {
                $set: action.payload.error.message,
              },
              flagged: {
                $set: action.payload.error.flagged,
              },
            },
          },
        });
      }

      return state;
    },
    clearFieldErrors: (state) => {
      return update(state, {
        nameOnCard: {
          error: {
            flagged: {
              $set: false,
            },
          },
        },
        cardNumber: {
          error: {
            flagged: {
              $set: false,
            },
          },
        },
        cvc: {
          error: {
            flagged: {
              $set: false,
            },
          },
        },
        expiry: {
          error: {
            flagged: {
              $set: false,
            },
          },
        },
        zipCode: {
          error: {
            flagged: {
              $set: false,
            },
          },
        },
      });
    },
    clearPaymentMethodForm: (state) => {
      return (state = paymentMethodForm);
    },
  },
});

export const {
  updateNameOnCard,
  updateCardNumber,
  updateExpiry,
  updateCvc,
  updateZip,
  clearFieldErrors,
  clearPaymentMethodForm,
} = paymentMethodFormSlice.actions;

export default paymentMethodFormSlice.reducer;
