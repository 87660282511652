import "./cases.scss";

import { ArrowRight, UsersThree } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReferralInviteForm, { ViewType } from "@components/ReferralInviteForm";
import { ParentComponent } from "@components/ReferralInviteForm/components/TravelGroupView";
import { useQuery } from "@hooks/useQuery";
import { Button } from "@runwayhealth/runway-components-react";
import { Case as ICase } from "@store/../@types/case";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import * as casesActions from "@store/cases/casesActions";
import { clearNewCase } from "@store/cases/casesSlice";
import { clearCasePrice, clearPrescriptionsPrices } from "@store/prices/pricesSlice";
import { LOGOUT } from "@store/user/userActions";
import { toastInfo } from "@utils/utils";

import Case from "./component/Case";
import NewCaseForm from "./component/NewCaseForm";

const Cases = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const casesState = useSelector<RootState, RootEntity<RootEntity<ICase>[]>>(
    (state) => state.case.cases
  );
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const [sortedByUpdatedAt, setSortedByUpdatedAt] = useState<RootEntity<ICase>[]>(casesState.data);

  const [referralFormIsShowing, setReferralFormIsShowing] = useState(false);
  const [newCaseFormIsShowing, setNewCaseFormIsShowing] = useState(false);

  const refreshCases = () => {
    if (user.data.id) {
      dispatch({ type: casesActions.REFRESH_CASES, userId: user.data.id });
    } else {
      sessionExpired();
    }
  };

  const sessionExpired = () => {
    toastInfo("Session expired", "Please login again to continue...");
    dispatch({
      type: LOGOUT,
      userId: user.data.id,
    });
  };

  useEffect(() => {
    const sortedByUpdatedAt = [...casesState.data].sort(
      (a, b) => new Date(b.data.updatedAt).getTime() - new Date(a.data.updatedAt).getTime()
    );
    setSortedByUpdatedAt(sortedByUpdatedAt);
  }, [casesState]);

  useEffect(() => {
    const openReferralModal = query.get("referral");
    const openNewCaseForm = query.get("cart");

    if (Boolean(openReferralModal) && openReferralModal === "true") {
      setReferralFormIsShowing(true);
    }

    if (Boolean(openNewCaseForm) && openNewCaseForm === "true") {
      setNewCaseFormIsShowing(true);
    } else {
      // Clear new case status if cart isn't selected.
      dispatch(clearNewCase());
      dispatch(clearPrescriptionsPrices());
      dispatch(clearCasePrice());
    }
  }, []);

  return (
    <div className="cases">
      {!newCaseFormIsShowing && !referralFormIsShowing && (
        <div className="express-menu">
          <div className="case-new">
            <div className="case-new-button" onClick={() => setReferralFormIsShowing(true)}>
              <UsersThree size={24}></UsersThree>
              <h4>Traveling with others?</h4>
            </div>
          </div>
          <div className="case-new">
            <div className="case-new-button" onClick={() => setNewCaseFormIsShowing(true)}>
              <h4>Start a new order</h4>
              <ArrowRight size={24}></ArrowRight>
            </div>
          </div>
        </div>
      )}
      <NewCaseForm
        isOpen={!referralFormIsShowing && newCaseFormIsShowing}
        closeForm={() => setNewCaseFormIsShowing(false)}
        openReferralForm={() => {
          setReferralFormIsShowing(true);
          setNewCaseFormIsShowing(false);
        }}
      />
      <ReferralInviteForm
        isOpen={referralFormIsShowing && !newCaseFormIsShowing}
        parentComponent={ParentComponent.DASHBOARD}
        closeForm={() => setReferralFormIsShowing(false)}
        view={ViewType.TRAVEL_GROUP}
      />
      {!referralFormIsShowing && !newCaseFormIsShowing && (
        <>
          <div className="refresh-button">
            <Button
              size="sm"
              onClick={refreshCases}
              iconName="ArrowClockwise"
              isLoading={casesState.isPending}
              variant="outline"
              disabled={casesState.isPending}
              iconPosition="left"
            >
              Refresh
            </Button>
          </div>
          {sortedByUpdatedAt.map((case_) => {
            return (
              <div className="case-wrapper" key={case_.data.id}>
                <Case case_={case_} customPharmacy={user.data.pharmacy_id} />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Cases;
