import React from "react";

import { UserType } from "@store/../@types/mdi";

import Loader from "../../../../../../img/Loader.svg";
import Message from "./Message/Message";

type ConversationProps = {
  messages: [];
  loadingChat: boolean;
  onScroll: () => void;
};

const Conversation = ({ messages, loadingChat, onScroll }: ConversationProps) => {
  return (
    <div className={`messages-chats-wrapper${loadingChat ? "-loader" : ""}`} onScroll={onScroll}>
      {messages.map((message: any) => (
        <Message
          key={message.id}
          userType={message.user_type}
          memberFirstName={message?.user?.first_name}
          message={message.text}
          time={message.created_at}
          file={message?.files ? message?.files[0] : undefined}
        />
      ))}
      {messages.length === 0 && !loadingChat && (
        <>
          <Message
            key={0}
            userType={UserType.SUPPORT}
            memberFirstName="Bot"
            message="Hey there! This is the chat with your clinician. Feel free to start the conversation and ask any questions you may have regarding your travel and medication needs."
            time={new Date()}
            file={undefined}
          />
        </>
      )}
      {loadingChat && (
        <>
          {" "}
          <img className="loader-img" src={Loader} />
        </>
      )}
    </div>
  );
};

export default React.memo(Conversation, (prevProps, nextProps) => {
  return prevProps.messages.length === nextProps.messages.length && !prevProps.loadingChat;
});
