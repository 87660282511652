import "./WhereToGo.scss";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { PageConsultation } from "@components/layouts/PageConsultation";
import TransitionComponent from "@components/TransitionComponent";
import { useTitle } from "@hooks/useTitle";
import { Button, Feedback, Text, Title } from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

import Header from "../../elements/Header";
import LongInput from "../../elements/LongInput";

export const DESTINATION_QUESTION = "Where are you traveling?";

const WhereToGo = () => {
  const dispatch = useDispatch();
  const [destination, setDestination] = useState<string | undefined>("");
  const [errorText, setErrorText] = useState("");
  const [upsell, setUpsell] = useState<boolean>(false);
  useTitle(DESTINATION_QUESTION);

  // Define the current question and reason
  const currentQuestion = "Where are you traveling?";
  const currentReason = "We ask this to inform your care team.";

  useEffect(() => {
    const country = sessionStorage.getItem("country");
    if (country) {
      const regions = new Intl.DisplayNames(["en"], { type: "region" });

      // Do the Tibet exception.
      if (country === "Tibet") {
        setDestination(country);
      } else {
        setDestination(regions.of(country));
      }
    }
  }, []);

  const handleClick = () => {
    if (!destination || destination.length <= 2) {
      setErrorText("Please enter the country or region you will be going to");
    } else {
      dispatch(
        questionsUpdate({
          question: DESTINATION_QUESTION,
          value: destination,
          questionType: "general",
        })
      );

      setUpsell(true);
    }
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && handleClick();
  };

  return (
    <>
      <Header progress={8} />
      {upsell ? (
        <>
          <TransitionComponent transitionTask="upsell" destination={destination} />
        </>
      ) : (
        <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
          <PageConsultation.Header>
            <Title size="giant">{currentQuestion}</Title>
            <Text size="md">{currentReason}</Text>
          </PageConsultation.Header>

          <PageConsultation.Body>
            <LongInput
              setErrorText={setErrorText}
              placeholder="Country, City, Place etc"
              handleChange={setDestination}
              value={destination}
              onFocus={undefined}
              name={undefined}
            />
            {errorText && (
              <Feedback isInvalid size="md" className="rw-page-consultation-feedback-message">
                {errorText}
              </Feedback>
            )}
          </PageConsultation.Body>

          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={handleClick}
            disabled={!destination || destination.length <= 2}
          >
            Next
          </Button>
        </PageConsultation>
      )}
    </>
  );
};

export default WhereToGo;
