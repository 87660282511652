import * as Sentry from "@sentry/react";

import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MaskInput from "react-maskinput";

import useDataLayer, { GA4Events } from "@hooks/useDataLayer";

import { SIGNUP } from "@store/user/userActions";
import { ErrorEvents } from "@store/../@types/state";
import { toastError } from "@utils/utils";

import { userUpdate } from "../../../store/user/userSlice";
import { registration, updateRegistrationObj } from "../../../utils/saveUserInfo";
import {
  validateFullname,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
} from "../../../utils/utilsFormvalidation";

import Header from "../../elements/Header";
import DocsModal from "../../elements/DocsModal";

import "./SignupPage.scss";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Checkbox from "@material-ui/core/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import InfoPasswordGrey from "./img/InfoPasswordGrey.svg";
import InfoPasswordBlue from "./img/InfoPasswordBlue.svg";
import InfoPasswordRed from "./img/InfoPasswordRed.svg";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { useTitle } from "@hooks/useTitle";
import { Link, Text, Button } from "@runwayhealth/runway-components-react";

const SignupPage = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const checkboxes = [
    {
      label: "I agree to",
      title: "Terms and Conditions",
      subTitle: "Privacy Policy",
      subTitle2: "Information Sharing",
      item: "terms",
      subitem: "privacy",
      subitem2: "information",
    },
    {
      label: "I agree to",
      title: "Telehealth Consent",
      item: "consent",
    },
  ];

  const questions = useSelector((state) => state.questions);
  const user = useSelector((state) => state.user);
  const { reportAccess } = useDataLayer(GA4Events.SIGN_UP);
  const [errorText, setErrorText] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(checkboxes.length).fill(false));
  const [openDocs, setOpenDocs] = useState(false);
  const [openFormHelp, setOpenFormHelp] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [docName, setDocName] = useState("");
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };
  useTitle("Sign Up");
  const history = useHistory();

  useEffect(() => {
    if (user.error.event === ErrorEvents.SERVER_ERROR) {
      setErrorText(user.error.message || "Unknown error.");
      toastError("Error", user.error.message);
    }
  }, [user.error]);

  useEffect(() => {
    let flatQuestions = Object.entries(questions)
      .map((entry) => {
        // Where 0 is the key and 1 is the value
        return entry[1];
      })
      .flat();
    if (user.id) {
      if (flatQuestions.length === 0) {
        history.push("/dashboard");
      } else {
        // For cases in which a consultation is being sent.
        history.push("/checkout");
      }
    } else if (flatQuestions.length === 0) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (user.data.id && localStorage.getItem("token")) {
      // Report signup analytics
      reportAccess();
      return history.push("/checkout");
    }
  }, [user.data.id]);

  useOutsideClick(ref, () => {
    if (openFormHelp) setOpenFormHelp(false);
  });

  const validateInput = (value, name) => {
    setErrorPassword("");
    setErrorText("");

    if (name === "password" && !validatePassword(value)) {
      setErrorPassword("Password must be at least 8 characters long.");
    }

    if (name === "first_name" && !validateName(value)) {
      setErrorText("Please enter a valid Name");
    }

    if (name === "last_name" && !validateName(value)) {
      setErrorText("Please enter a valid Last Name");
    }

    if (name === "phone" && !validatePhone(value)) {
      setErrorText("Please enter your phone number");
    }

    if (name === "email" && !validateEmail(value)) {
      setErrorText("Please enter your email address");
    }
  };

  const formIsValid = () => {
    if (!validateFullname(registration.first_name, registration.last_name)) {
      setErrorText("Please enter your full name");
      return false;
    }
    if (!validateEmail(registration.email)) {
      setErrorText("Please enter your email address");
      return false;
    }
    if (!validatePassword(registration.password)) {
      setErrorPassword("Please enter a more secure password.");
      return false;
    }
    if (!validatePhone(registration.phone)) {
      setErrorText("Please enter your phone number");
      return false;
    }
    if (checkedState.includes(false)) {
      setErrorText("Please accept our terms and conditions");
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (formIsValid()) {
      // Submit signup data.
      dispatch({
        type: SIGNUP,
        user: user.data,
      });
    }
  };
  const handleOpener = (value) => {
    setDocName(value);
    setOpenDocs(true);
  };

  return (
    <div className="sign-up-page">
      <Header progress={99} />
      <div className="form-wrapper">
        <div className="sign-container">
          <div className="sign-up-content">
            <div className="signup-form">
              <label>Checkout</label>
              <label className="sign-up">Finish setting up your account</label>
              <div className="form">
                <div className="input-names">
                  <input
                    className="signup-input-names"
                    autocomplete="off"
                    placeholder="First Name"
                    id="first-name"
                    onChange={(e) => {
                      updateRegistrationObj("first_name", e.target.value);
                      dispatch(
                        userUpdate({
                          name: "first_name",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={(e) => {
                      validateInput(e.target.value, "first_name");
                    }}
                  />
                  <input
                    className="signup-input-names"
                    placeholder="Last Name"
                    autocomplete="off"
                    id="last-name"
                    onChange={(e) => {
                      updateRegistrationObj("last_name", e.target.value);
                      dispatch(
                        userUpdate({
                          name: "last_name",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={(e) => {
                      validateInput(e.target.value, "last_name");
                    }}
                  />
                </div>
                <input
                  className="signup-input"
                  placeholder="Email Address"
                  id="email"
                  type="email"
                  autocomplete="on"
                  value={user.email}
                  onChange={(e) => {
                    updateRegistrationObj("email", e.target.value);
                    dispatch(userUpdate({ name: "email", value: e.target.value }));
                  }}
                  onBlur={(e) => validateInput(e.target.value, "email")}
                />
                <MaskInput
                  className="signup-input"
                  mask={"(000) 000-0000"}
                  size={20}
                  type="tel"
                  showMask={false}
                  maskChar="_"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    updateRegistrationObj("phone", e.target.value);
                    validateInput(e.target.value, "phone");
                    dispatch(
                      userUpdate({
                        name: "phone_number",
                        value: e.target.value,
                      })
                    );
                    dispatch(
                      userUpdate({
                        name: "phone_type",
                        value: 2,
                      })
                    );
                  }}
                />
                <div className="password-signup-footer">
                  <p className="signup-label">CREATE PASSWORD</p>

                  <div
                    id="password-signup-footer-input"
                    ref={ref}
                    className="password-signup-footer-input"
                  >
                    <div
                      className={
                        openFormHelp
                          ? errorPassword
                            ? "container-helpform active errorExist"
                            : "container-helpform active"
                          : "container-helpform"
                      }
                    >
                      <span className={errorPassword ? "top-bot errorExist" : "top-bot"}></span>
                      <div className="container-text">
                        <p>Your password must:</p>
                        <ul className="list-items">
                          <li>contain at least 8 characters</li>
                          {errorPassword ? <li>be different from the previous password</li> : null}
                        </ul>
                      </div>
                    </div>
                    <input
                      id="password"
                      className="signup-input password"
                      autocomplete="new-password"
                      type={isPasswordHidden ? "password" : "text"}
                      placeholder="Create Password"
                      onChange={(e) => {
                        updateRegistrationObj("password", e.target.value);
                        validateInput(e.target.value, "password");
                        dispatch(
                          userUpdate({
                            name: "password",
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <div className={"img-container"}>
                      {errorPassword && (
                        <img
                          className="imgItemInfo"
                          onClick={() => setOpenFormHelp(!openFormHelp)}
                          src={InfoPasswordRed}
                          alt=""
                        />
                      )}
                      {!errorPassword && (
                        <img
                          className="imgItemInfo"
                          onClick={() => setOpenFormHelp(!openFormHelp)}
                          src={openFormHelp ? InfoPasswordBlue : InfoPasswordGrey}
                          alt=""
                        />
                      )}
                      <div className="login-img">
                        {isPasswordHidden ? (
                          <VisibilityOff
                            className="show"
                            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                          />
                        ) : (
                          <Visibility
                            className="show"
                            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      errorPassword || errorText
                        ? "error-message-signup active"
                        : "error-message-signup"
                    }
                  >
                    <p className="message-content-signup">{errorPassword || errorText}</p>
                  </div>
                </div>

                <div className="agreement">
                  {checkboxes.map((checkbox, index) => (
                    <div key={index} className="terms">
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleChecked />}
                        checked={checkedState[index]}
                        onClick={() => setErrorText(false)}
                        onChange={() => handleOnChange(index)}
                        color="default"
                      />
                      <Text as="div">
                        {checkbox.label}{" "}
                        <Link as="span" onClick={() => handleOpener(checkbox.item)}>
                          {checkbox.title}
                        </Link>
                        {checkbox.subTitle && ", "}
                        <Link as="span" onClick={() => handleOpener(checkbox.subitem)}>
                          {checkbox?.subTitle}
                        </Link>
                        {checkbox.subTitle2 && " and "}
                        <Link as="span" onClick={() => handleOpener(checkbox.subitem2)}>
                          {checkbox?.subTitle2}
                        </Link>
                      </Text>
                    </div>
                  ))}
                </div>

                <div className="button-div">
                  <Button
                    id="signup-btn"
                    size="lg"
                    customWidth={440}
                    iconName="ArrowRight"
                    type="submit"
                    isLoading={user.isPending}
                    onClick={() => submitForm()}
                  >
                    Complete my visit
                  </Button>
                  {openDocs && <DocsModal open={openDocs} close={setOpenDocs} name={docName} />}
                </div>
                <div className="login-link-container">
                  <Text>
                    Already a member?{" "}
                    <Link
                      as="span"
                      onClick={() => history.push("/login", { from: "registration" })}
                    >
                      Log in
                    </Link>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
