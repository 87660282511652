import update from "immutability-helper";

import { createSlice } from "@reduxjs/toolkit";
import { RootEntity } from "@store/../@types/state";
import { Gender, User } from "@store/../@types/user";

export const userObject: RootEntity<User> = {
  isPending: false,
  data: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    phone_type: 2,
    date_of_birth: new Date(),
    height: 0,
    weight: 0,
    active: true,
    gender_label: "",
    current_medications: "",
    medicalConditions: "",
    allergies: "",
    "patient-id": null,
    pregnancy: false,
    addresses: [],
    password: "",
    confirmPassword: "",
    driver_license_id: "",
    isAdmin: false,
    pharmacy_id: "",
    paymentMethods: [],
    defaultPaymentMethod: "",
    promocodes: [],
  },
  error: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: userObject,
  reducers: {
    userPending: (state) => {
      return Object.assign({}, state, {
        isPending: true,
      });
    },
    userError: (state, action) => {
      return Object.assign({}, state, { error: action.payload, isPending: false });
    },
    userUpdate: (state, action) => {
      if (action.payload.name === "password") {
        return Object.assign({}, state, {
          data: {
            ...state.data,
            password: action.payload.value,
            confirmPassword: action.payload.value,
          },
        });
      }
      if (action.payload.name === "gender_label") {
        switch (action.payload.value) {
          case "Male":
            return Object.assign({}, state, {
              data: {
                ...state.data,
                gender: Gender.Male,
                gender_label: action.payload.value,
              },
            });
          case "Female":
            return Object.assign({}, state, {
              data: {
                ...state.data,
                gender: Gender.Female,
                gender_label: action.payload.value,
              },
            });
          case "Other":
            return Object.assign({}, state, {
              data: {
                ...state.data,
                gender: Gender.Other,
                gender_label: action.payload.value,
              },
            });
        }
      }

      return Object.assign({}, state, {
        data: {
          ...state.data,
          [action.payload.name]: action.payload.value,
        },
      });
    },
    userMutationSuccess: (state, action) => {
      return update(state, {
        isPending: {
          $set: false,
        },
        data: {
          $set: action.payload,
        },
      });
    },
    clearUser: (state) => {
      return (state = userObject);
    },
    cleanUserError: (state) => {
      return Object.assign({}, state, { error: {} });
    },
  },
});

export const {
  userPending,
  userError,
  userMutationSuccess,
  userUpdate,
  clearUser,
  cleanUserError,
} = userSlice.actions;

export default userSlice.reducer;
