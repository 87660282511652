import LoginPage from "./components/pages/LoginPage";
import SecurityCodePage from "./components/pages/Security-Code";
import SetNewPasswordPage from "./components/pages/SetNewPassword";
import ForgotPasswordPage from "./components/pages/ForgotPassword";
import SignupPage from "./components/pages/SignupPage";
import TravelersDiarrhea from "./components/pages/TravelersDiarrhea";
import MotionSickness from "./components/pages/MotionSickness";
import AltitudeSickness from "./components/pages/AltitudeSickness";
import Insomnia from "./components/pages/Insomnia";
import TravellingLong from "./components/pages/TravellingLong";
import WhatState from "./components/pages/WhatState";
import TakeDate from "./components/pages/TakeDate";
import Insurance from "./components/pages/Insurance";
import DestinationType from "./components/pages/DestinationType";
import TripType from "./components/pages/TripType";
import ParentalConsent from "./components/pages/ParentalConsent";
import IdentifyGender from "./components/pages/IdentifyGender";
import TakeID from "./components/pages/TakeID";
import CurrentPregnant from "./components/pages/CurrentPregnant";
import CurrentFeeding from "./components/pages/CurrentFeeding";
import FollowingConditions from "./components/pages/FollowingConditions";
import Allergies from "./components/pages/Allergies";
import Vaccines from "./components/pages/Vaccines";
import RoutineVaccines from "./components/pages/RoutineVaccines/";
import AnyProblems from "./components/pages/AnyProblems";
import WhereToGo from "./components/pages/WhereToGo";
import PharmacyPage from "./components/pages/PharmacyPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import Ascent from "./components/pages/Ascent";
import MountainSickness from "./components/pages/MountainSickness";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Alone from "./components/pages/Alone";
import StayingAsleep from "./components/pages/StayingAsleep";
import CurrentDia from "./components/pages/CurrentDia";
import BowelDisease from "./components/pages/BowelDisease";
import CDiff from "./components/pages/CDiff";
import WakingUp from "./components/pages/WakingUp";
import docViewer from "./components/pages/LoginPage/docs/docViewer";
import TimeZone from "./components/pages/TimeZone";
import Privacy from "./components/pages/LegalDocuments/Privacy";
import Terms from "./components/pages/LegalDocuments/Terms";
import Consent from "./components/pages/LegalDocuments/Consent";
import Activities from "./components/pages/Activities";
import SideEffects from "./components/pages/SideEffects/";
import ExperiencedIssues from "./components/pages/ExperiencedIssues/";
import MedicationsPlan from "./components/pages/MedicationsPlan/";
import ConditionsMalarone from "./components/pages/ConditionsMalarone/";
import WhenDepart from "./components/pages/WhenDepart/";
import Delivery from "./components/pages/Delivery/";
import SyncState from "./components/pages/SyncState";
import RunwayPlus from "./components/pages/RunwayPlus";
import PlusDetails from "./components/pages/RunwayPlusDetails";
import MedicationsTaken from "./components/pages/MedicationsTaken";
import MarketingChannels from "./components/pages/MarketingChannels";
import HeightAndWeight from "@components/pages/HeightAndWeight";
import UpsellPage from "@components/pages/UpsellPage";
import Infection from "@components/pages/Infection";
import FluconazolePage from "@components/pages/FluconazolePage";
import ImmunocompromisedPage from "@components/pages/Immunocompromised";
import NitrofurantoinPage from "@components/pages/Nitrofurantoin";
import WelcomeBack from "@components/pages/WelcomeBack";
import ConfirmID from "@components/pages/ConfirmID";

export const pages = {
  LoginPage,
  SignupPage,
  TravelersDiarrhea,
  MotionSickness,
  AltitudeSickness,
  Insomnia,
  Activities,
  SideEffects,
  ExperiencedIssues,
  MedicationsPlan,
  ConditionsMalarone,
  TravellingLong,
  WhatState,
  TakeDate,
  Insurance,
  DestinationType,
  TripType,
  IdentifyGender,
  ParentalConsent,
  TakeID,
  CurrentPregnant,
  CurrentFeeding,
  FollowingConditions,
  Allergies,
  Vaccines,
  RoutineVaccines,
  AnyProblems,
  WhereToGo,
  PharmacyPage,
  CheckoutPage,
  Ascent,
  MountainSickness,
  Dashboard,
  Alone,
  StayingAsleep,
  CurrentDia,
  BowelDisease,
  CDiff,
  WakingUp,
  docViewer,
  TimeZone,
  Privacy,
  Terms,
  Consent,
  WhenDepart,
  Delivery,
  ForgotPasswordPage,
  SecurityCodePage,
  SetNewPasswordPage,
  SyncState,
  RunwayPlus,
  PlusDetails,
  MedicationsTaken,
  MarketingChannels,
  HeightAndWeight,
  UpsellPage,
  Infection,
  FluconazolePage,
  ImmunocompromisedPage,
  NitrofurantoinPage,
  WelcomeBack,
  ConfirmID,
};
