import * as Sentry from "@sentry/react";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { user as userApi } from "../../../api";
import { userUpdate } from "@store/user/userSlice";
import { error_change } from "@store/ui/uiAction";
import { USER_UPDATE } from "@store/user/userActions";
import { Button, Feedback } from "@runwayhealth/runway-components-react";
import { ModalBody, ModalFooter } from "@components/elements/ModalBase/ModalBase";
import { toast } from "react-toastify";
import { ConditionTypes } from "@store/../@types/condition";

const PreviewFile = ({ selectedFile, uploadFile, setImg, next, minor, close, userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector((state) => state.ui.error);
  const [isLoading, setIsloading] = useState(false);
  const [imgStatus, setImgStatus] = useState(null);

  const conditions = useSelector((state) => state.case.newCase.data.conditions);

  const allowedImgSize = selectedFile.size >= 25000000;

  const uploadFilesApi = async () => {
    if (allowedImgSize) {
      return;
    }

    const form = new FormData();
    form.append("photo-id", selectedFile);

    try {
      const { data, status } = await userApi.userPost("photo-id", form);
      if (userId === "") {
        dispatch(userUpdate({ name: "driver_license_id", value: data }));
      } else {
        dispatch({
          type: USER_UPDATE,
          userUpdate: {
            driver_license_id: data,
          },
        });
      }
      setImgStatus(status);
      setIsloading(false);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(error_change("The photo could not be saved. Please try again."));
      setIsloading(false);
    }
  };

  const savePhoto = () => {
    setIsloading(true);
    uploadFilesApi();
  };

  useEffect(() => {
    if (imgStatus === 200 && next) {
      toast.success("Photo uploaded successfully", { autoClose: 1800 });
      if (minor) {
        handleGoBack();
        closeModal();
      } else {
        history.push("/id");
      }
    }

    if (imgStatus === 200 && !next) {
      toast.success("Photo uploaded successfully", { autoClose: 1800 });
      if (minor) {
        handleGoBack();
        closeModal();
      } else {
        const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
        // If new case is Plus, then push plus details question.
        if (consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? conditions.length === 0) {
          history.push("/plus_details");
        } else {
          // TODO: continue with the flow.
        }
      }
    }
  }, [imgStatus]);

  const handleGoBack = () => setImg("");

  const closeModal = () => close(false);

  return (
    <ModalBody>
      <img src={uploadFile} alt="data" />

      <ModalFooter>
        <Button variant="outline" customWidth={220} onClick={handleGoBack}>
          Go back
        </Button>
        <Button
          customWidth={220}
          onClick={() => savePhoto()}
          isLoading={isLoading}
          disabled={allowedImgSize}
        >
          Capture ID
        </Button>
      </ModalFooter>
      {error && (
        <Feedback isInvalid className="rw-modal-error-message">
          {error}
        </Feedback>
      )}
      {allowedImgSize && (
        <Feedback isInvalid className="rw-modal-error-message">
          Your file size is over 25 MB and cannot be uploaded. Please select a smaller file.
        </Feedback>
      )}
    </ModalBody>
  );
};

export default PreviewFile;
