import { useEffect, useState } from "react";
import { Noop } from "react-hook-form";

import { FormControl, MenuItem } from "@material-ui/core";

import { CustomInputBase, StateSelectField, StateSelectPlaceholder } from "./components/Select";

export type USState = {
  name: string;
  abbreviation: string;
  isAvailable: boolean;
};

export type StateSelectProps = {
  name: string;
  value: string;
  options: USState[];
  onChange: (stateAbbrv: string) => void;
  onBlur?: Noop;
};

const StateSelect = ({ name, value, options, onChange }: StateSelectProps) => {
  const [localValue, setLocalValue] = useState(value ?? "");
  const [label, setLabel] = useState("Select a state");

  useEffect(() => {
    setLocalValue(value ?? "state");

    // If value that changes is not empty, then remove label (placeholder)
    if (value !== "") {
      setLabel("");
    }
  }, [value]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setLabel("");
    const value = e.target.value;
    setLocalValue(value as string);
    if (onChange) {
      onChange(value as string);
    }
  };

  return (
    <FormControl className="ph-no-capture">
      <StateSelectPlaceholder>{label}</StateSelectPlaceholder>
      <StateSelectField
        input={<CustomInputBase />}
        name={name}
        value={localValue}
        onChange={handleChange}
      >
        {options?.map((option) => {
          const { abbreviation } = option;
          return (
            <MenuItem className="ph-no-capture" key={abbreviation} value={abbreviation}>
              {option.name}
            </MenuItem>
          );
        })}
      </StateSelectField>
    </FormControl>
  );
};

export default StateSelect;
