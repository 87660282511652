import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { border, color, opacity, spacing } from "@runwayhealth/runway-components-react";
import { Case, ShippingMethods } from "@store/../@types/case";
import { Condition } from "@store/../@types/condition";
import { updateCaseShipping } from "@store/admin/adminAction";
import { caseObject } from "@store/cases/casesSlice";

import { getInvoiceIdLink, getMdiLink } from "./CaseSearch";
import CaseAddress from "./components/CaseAddress";
import ConditionsTable from "./components/ConditionsTable";

interface IProps {
  cases: Case[];
}

const CaseResults = ({ cases }: IProps) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [window.innerWidth]);

  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      backgroundColor: `${color.neutral.lightest}`,
      borderRadius: screenWidth < 768 ? `${border.radius.lg}` : `${border.radius.xl}`,
      height: "auto",
      margin: `${spacing.nano}`,
      width: screenWidth < 768 ? "100%" : screenWidth < 992 ? "82%" : "74%",
      maxWidth: "1200px",
      position: "relative",
      maxHeight: "100%",
      overflowY: "scroll",
      padding: "45px",
    },
  }));
  const classes = useStyles();

  const dispatch = useDispatch();

  const shippingMethodAlert = (case_: Case, newShippingMethod: ShippingMethods) => {
    const {
      id,
      caseId,
      CurexaOrder: { order_id: referenceId, id: orderId },
    } = case_;
    if (
      confirm(
        `Are you sure you want to set ${newShippingMethod} shipping method to Case: ${caseId}?`
      )
    ) {
      dispatch(
        updateCaseShipping({ caseId: id, shipping_method: newShippingMethod, orderId, referenceId })
      );
    }
  };

  const [modal, setModal] = useState(false);
  const [conditionTableIsOpen, setConditionTableIsOpen] = useState(false);
  const [caseAddressIsOpen, setCaseAddressIsOpen] = useState(false);
  const [caseConditions, setCaseConditions] = useState<Condition[]>([]);
  const [selectedCase, setSelectedCase] = useState<Case>(caseObject.data);
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Case ID</th>
            <th>MDI Case ID</th>
            <th>Order Id</th>
            <th>Payment Status</th>
            <th>Conditions</th>
            <th>Case Status</th>
            <th>Order Status</th>
            <th>Departure Date</th>
            <th>Shipping Method</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {cases.map((case_) => {
            return (
              <tr key={`case-${case_.id}`}>
                <td>{case_.id}</td>
                <td>{getMdiLink(case_.caseId || "N/A")}</td>
                <td>{case_.CurexaOrder?.order_id || "N/A"}</td>
                <td>{getInvoiceIdLink(case_.invoiceId || "❌")}</td>
                <td>
                  <button
                    onClick={() => {
                      setCaseConditions(case_.conditions);
                      setModal(true);
                      setConditionTableIsOpen(true);
                    }}
                  >
                    Show conditions
                  </button>
                </td>
                <td>{case_.case_status || "N/A"}</td>
                <td>{case_.CurexaOrder?.status || "N/A"}</td>
                <td>{case_.departureDate}</td>
                <FormControl fullWidth>
                  <Select
                    value={case_.shipping_method ?? ShippingMethods.STANDARD}
                    label="Shipping Method"
                    onChange={(evt: React.ChangeEvent<{ value: unknown }>) => {
                      const chosenShippingMethod: ShippingMethods = evt.target
                        .value as ShippingMethods;
                      shippingMethodAlert(case_, chosenShippingMethod);
                    }}
                  >
                    {Object.keys(ShippingMethods).map((shippingMethod: string) => {
                      const key: keyof typeof ShippingMethods =
                        shippingMethod as keyof typeof ShippingMethods;
                      return (
                        <MenuItem value={ShippingMethods[key]}>{ShippingMethods[key]}</MenuItem>
                      );
                    })}
                  </Select>
                  <button
                    onClick={() => {
                      setSelectedCase(case_);
                      setModal(true);
                      setCaseAddressIsOpen(true);
                    }}
                  >
                    Shipping Address
                  </button>
                </FormControl>
                <td>{case_.updatedAt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          setConditionTableIsOpen(false);
          setCaseAddressIsOpen(false);
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: `${color.neutral.darkest}`, opacity: `${opacity.semiOpaque}` },
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            {" "}
            {conditionTableIsOpen ? (
              <ConditionsTable conditions={caseConditions} />
            ) : (
              <>
                {caseAddressIsOpen && (
                  <CaseAddress
                    case_={selectedCase}
                    closeModal={() => {
                      setModal(false);
                      setCaseAddressIsOpen(false);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CaseResults;
