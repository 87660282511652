import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";

import {
    ModalBody, ModalCamContainer, ModalFooter
} from "@components/elements/ModalBase/ModalBase";
import useAmplitude from "@hooks/useAmplitude";
import { Button, Feedback } from "@runwayhealth/runway-components-react";
import * as Sentry from "@sentry/react";
import { ConditionTypes } from "@store/../@types/condition";
import { error_change } from "@store/ui/uiAction";
import { USER_UPDATE } from "@store/user/userActions";

import { Condition } from "../../../@types/condition";
import { RootState } from "../../../@types/state";
import { user } from "../../../api";
import { userUpdate } from "../../../store/user/userSlice";

const calculateImageSize = (img64: string) => {
  //Get the last two characters of base64 image
  const lastCharacters = `${img64.charAt(img64.length - 2)}${img64.charAt(img64.length - 1)}`;

  // Depends of `lastCharacters` value will be set `y` value
  const y = lastCharacters === "==" ? 2 : 1;

  // Returning size in bytes, using the formula: x = (n * (3/4)) - y
  // `x` is the size of a file in bytes
  // `n` is the length of the Base64 String
  // `y` will be 2 if `img64` ends with '==' and 1 if `img64` ends with '='.
  return img64.length * (3 / 4) - y;
};

const WebcamCapture = ({
  cam,
  next,
  minor,
  close,
  userId,
}: {
  cam: React.Dispatch<React.SetStateAction<boolean>>;
  next: boolean;
  minor: boolean;
  close: any;
  userId: string;
}) => {
  const [imgSrc, setImgSrc] = useState<string>("");
  const [isLoading, setIsloading] = useState(false);
  const [imgStatus, setImgStatus] = useState<number | null>(null);
  const [cams, setCams] = useState<MediaDeviceInfo[]>([]);
  const [isSelfieCam, setIsSelfieCam] = useState(true);
  const [allowedImgSize, setAllowedImgSize] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.ui.error);
  const { logEvent } = useAmplitude();
  const history = useHistory();

  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );

  const webcamRef = useRef<Webcam>(null);

  const handleCapturePhoto = () => {
    logEvent("CAPTURE_WEBCAM_PHOTO");
    const img64 = webcamRef.current?.getScreenshot();

    if (!img64) {
      return;
    }

    setImgSrc(img64);

    const sizeInBytes = calculateImageSize(img64);
    setAllowedImgSize(sizeInBytes >= 25000000);
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(",");
    //@ts-ignore
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const uploadFilesApi = async () => {
    if (allowedImgSize) {
      return;
    }

    logEvent("MDI_UPLOAD_PHOTO");

    const form = new FormData();
    const file = dataURLtoFile(imgSrc, "document");
    form.append("photo-id", file);

    try {
      const { data, status } = await user.userPost("photo-id", form);
      if (userId === "") {
        dispatch(userUpdate({ name: "driver_license_id", value: data }));
      } else {
        dispatch({
          type: USER_UPDATE,
          userUpdate: {
            driver_license_id: data,
          },
        });
      }
      setImgStatus(status);
      setIsloading(false);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(error_change("The photo could not be saved. Please try again."));
      setIsloading(false);
    }
  };

  const savePhoto = () => {
    setIsloading(true);
    uploadFilesApi();
  };

  useEffect(() => {
    if (imgStatus === 200 && next) {
      toast.success("Photo uploaded successfully", { autoClose: 1800 });
      if (minor) {
        cam(false);
        handleGoBack();
        closeModal();
      } else {
        history.push("/id");
      }
    }

    if (imgStatus === 200 && !next) {
      toast.success("Photo uploaded successfully", { autoClose: 1800 });
      if (minor) {
        cam(false);
        handleGoBack();
        closeModal();
      } else {
        const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
        // If new case is Plus, then push plus details question.
        if (consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? conditions.length === 0) {
          history.push("/plus_details");
        } else {
          // TODO: Continue with the flow.
        }
      }
    }
  }, [imgStatus]);

  const handleRotateCamera = () => {
    setIsSelfieCam((prevValue) => !prevValue);
  };

  const videoConstraints = {
    facingMode: isSelfieCam ? "user" : { exact: "environment" },
  };

  const getAllDevices = async () => {
    const response = await navigator.mediaDevices.enumerateDevices();
    const getCamsOnly = response.filter((el) => el.kind === "videoinput");
    setCams(getCamsOnly);
  };

  const handleGoBack = () => {
    setImgSrc("");
    setAllowedImgSize(false);
  };

  const closeModal = () => close(false);

  return (
    <ModalBody>
      {!imgSrc ? (
        <ModalCamContainer>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={getAllDevices}
          />
          {cams.length > 1 && (
            <Button
              variant="secondary"
              isIconOnly
              iconName="CameraRotate"
              className="rw-modal-button-camera-rotate"
              onClick={handleRotateCamera}
            />
          )}
        </ModalCamContainer>
      ) : (
        <img src={imgSrc} alt="Captured photo" />
      )}
      {imgSrc ? (
        <ModalFooter>
          <Button variant="outline" customWidth={220} onClick={handleGoBack}>
            Go back
          </Button>
          <Button
            customWidth={220}
            onClick={() => savePhoto()}
            isLoading={isLoading}
            disabled={allowedImgSize}
          >
            Save
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button variant="outline" customWidth={220} onClick={() => cam(false)}>
            Go back
          </Button>
          <Button customWidth={220} onClick={handleCapturePhoto}>
            Capture ID
          </Button>
        </ModalFooter>
      )}
      {error && (
        <Feedback isInvalid className="rw-modal-error-message">
          {error}
        </Feedback>
      )}
      {allowedImgSize && (
        <Feedback isInvalid className="rw-modal-error-message">
          Your file size is over 25 MB and cannot be uploaded. Please select a smaller file.
        </Feedback>
      )}
    </ModalBody>
  );
};

export default WebcamCapture;
