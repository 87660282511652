import "./thankYou.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Header from "@elements/Header";
import Ok from "@img/Ok";
import { Text } from "@runwayhealth/runway-components-react";
import { createMessage } from "@store/messages/messagesAction";

import { useHistory } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { useTitle } from "@hooks/useTitle";
import useDataLayer, { GA4Events } from "../../../hooks/useDataLayer";

import { clearCasePrice, clearPrescriptionsPrices } from "@store/prices/pricesSlice";
import { clearNewCase } from "@store/cases/casesSlice";
import ReferralInviteForm, { ViewType } from "@components/ReferralInviteForm";

import { CLINICIAN_INITIAL_QUESTION } from "../RunwayPlusDetails";

const ThankYou = () => {
  const { reportPurchasedProducts } = useDataLayer(GA4Events.PURCHASE);
  const [referralFormIsShowing, setReferralFormIsShowing] = useState(false);
  const { width } = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();

  const newCase = useSelector((state) => state.case.newCase.data);
  const conditions = newCase.conditions;
  const patientFirstChatMessage = useSelector(
    (state) =>
      state.case.newCase.data.case_questions.find(
        (questionObj) => questionObj.question === CLINICIAN_INITIAL_QUESTION
      )?.answer
  );
  useTitle("Thank You!");

  // Case space cleanup.
  useEffect(() => {
    // If state is formed.
    // Report purchase products for marketing analytics.
    // And send clinician initial message.
    if (newCase.invoiceId) {
      reportPurchasedProducts();
      if (patientFirstChatMessage?.length > 0) {
        dispatch(
          createMessage({
            text: patientFirstChatMessage,
            message_files: {
              file: null,
              path: "",
            },
          })
        );
      }
    }

    // Always clear state.
    dispatch(clearNewCase());
    dispatch(clearPrescriptionsPrices());
    dispatch(clearCasePrice());

    // Remove referral cookie
    Cookies.remove("rewardful.referral");
  }, []);

  return (
    <div className={"thank-you"}>
      <Header progress={100} background={"#F7F9FE"} hideBackButton={true} />
      <div className="content-wrapper">
        <h2 className="thank-you-title">Thank you</h2>
        <h3 className="thank-you-subtitle">You're all set!</h3>
        <p className="thank-you-description">
          We'll email you when your physician has reviewed your consultation.
        </p>
        <div className="stepper">
          <div className="stepper-block">
            <div className="stepper-number-circle">1</div>
            <span className="stepper-title">Consultation</span>
            <div className="stepper-status-circle">
              <Ok color={"#FFFFFF"} />
            </div>
            <button className="stepper-button">Done</button>
          </div>
          <div className="stepper-block">
            <div className="stepper-number-circle">2</div>
            <span className="stepper-title">Payment</span>
            <div className="stepper-status-circle">
              <Ok color={"#FFFFFF"} />
            </div>
            <button className="stepper-button">Done</button>
          </div>
          {width < 560 ? (
            <div className="stepper-block adaptive">
              <div className="stepper-block-wrapper">
                <div className="stepper-number-circle progress">3</div>
                <span className="stepper-title">Medical review</span>
                <div className="stepper-status-circle progress">
                  <Ok />
                </div>
              </div>
              <button className="stepper-button progress">Pending</button>
            </div>
          ) : (
            <div className="stepper-block">
              <div className="stepper-number-circle progress">3</div>
              <span className="stepper-title">Medical review</span>
              <div className="stepper-status-circle progress">
                <Ok />
              </div>
              <button className="stepper-button progress">Pending</button>
            </div>
          )}
        </div>
        <div className="go-to-dashboard">
          <h2>Traveling with others?</h2>
          <div className="group-travel-notice">
            <Text className="group-travel-description" as="p" size="lg">
              To protect patient information, each traveler is required to create their own Runway
              account.
            </Text>
          </div>
          {!referralFormIsShowing && (
            <button className="support-button" onClick={() => setReferralFormIsShowing(true)}>
              Refer companions
            </button>
          )}
          <ReferralInviteForm
            isOpen={referralFormIsShowing}
            closeForm={() => setReferralFormIsShowing(false)}
            view={ViewType.TRAVEL_GROUP}
          />
        </div>
        <div className="go-to-dashboard">
          <h2>Any questions?</h2>
          <div className="support">
            <div className="support-block">
              {width < 560 ? (
                <p>Our medical team will message you within 24 hours with next steps. </p>
              ) : null}
              <p>Feel free to email our support team at</p>
              <a href="mailto:support@runwayhealth.com." target={"_blank"}>
                support@runwayhealth.com
              </a>
            </div>
            <div className="support-block">
              <span>We’ll respond during business hours</span>
              <span> Monday-Friday</span>
              <span> 8AM-5PM EST</span>
            </div>
          </div>
          <button className="support-button" onClick={() => history.push("/dashboard/cases")}>
            Go to my dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
