import { Button, Text, TextField, Title } from "@runwayhealth/runway-components-react";
import { toastInfo } from "@utils/utils";

interface IRewardView {
  affiliateLink?: string;
}

const RewardView = ({ affiliateLink }: IRewardView) => {
  return (
    <>
      <Text>
        Share Runway Health and get rewarded! When you refer your friends and fellow travelers,
        you'll earn a credit on your next consultation. It's our way of saying thank you for your
        trust and support.
      </Text>
      <br></br>
      <br></br>
      <Title size="md">
        &nbsp;1.&nbsp;Share your referral link with friends and fellow travelers.
      </Title>
      <Title size="md">2. They get $15 off their first consultation.</Title>
      <Title size="md">3. You get a $15 credit to use on your next consultation.</Title>
      <br></br>
      <br></br>
      <Text>Start enjoying the benefits of helping others discover Runway.</Text>
      <div className="reward-view-actions-wrapper">
        <TextField className="referral-link-text-field" value={affiliateLink ?? "..."}></TextField>
        <Button
          iconName="LinkSimple"
          size="md"
          variant="primary"
          onClick={() => {
            navigator.clipboard.writeText(affiliateLink ?? "");
            toastInfo("Copied to clipboard!", affiliateLink);
          }}
          isIconOnly
        />
      </div>
    </>
  );
};

export default RewardView;
