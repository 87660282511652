import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import useAmplitude from "@hooks/useAmplitude";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { Condition, ConditionTypes } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";
import { questionsUpdate } from "@store/questions/questionsSlice";

import DestinationsVisiting from "../../templates/DestinationsVisiting/DestinationsVisiting";

const DestinationType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logEvent } = useAmplitude();

  // Define the current question and reason
  const currentQuestion = "What types of destinations will you be visiting?";
  const currentReason = "We ask this to inform your care team.";

  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );

  const consultations =
    conditions.length > 0
      ? conditions.map((condition) => condition.product.name)
      : [ConditionTypes.RUNWAY_PLUS];

  const currentNextRoute = (route: ConditionTypes) => {
    if (consultations.includes(route)) {
      return consultations;
    }
  };

  useEffect(() => {
    logEvent("DESTINATION_TYPE_VISITED", { consultations });
  }, [consultations, logEvent]);

  useTitle("Destination Type");

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType: "general",
      })
    );

    switch (consultations) {
      case currentNextRoute(ConditionTypes.DIARRHEA_CONSULTATION): {
        history.push("/start/dia");
        break;
      }
      case currentNextRoute(ConditionTypes.MALARIA_CONSULTATION): {
        history.push("/activities");
        break;
      }
      case currentNextRoute(ConditionTypes.ALTITUDE_CONSULTATION): {
        history.push("/start/altitude");
        break;
      }
      case currentNextRoute(ConditionTypes.MOTION_CONSULTATION): {
        history.push(`/start/motion/${ConditionTypes.MOTION_CONSULTATION}`);
        break;
      }
      case currentNextRoute(ConditionTypes.NAUSEA_CONSULTATION): {
        history.push(`/start/motion/${ConditionTypes.NAUSEA_CONSULTATION}`);
        break;
      }
      case currentNextRoute(ConditionTypes.INSOMNIA_CONSULTATION): {
        history.push("/start/insomnia");
        break;
      }
      case currentNextRoute(ConditionTypes.VAGINAL_YEAST_INFECTION): {
        history.push(`/start/infection/${ConditionTypes.VAGINAL_YEAST_INFECTION}`);
        break;
      }
      case currentNextRoute(ConditionTypes.URINARY_TRACT_INFECTION): {
        history.push(`/start/infection/${ConditionTypes.URINARY_TRACT_INFECTION}`);
        break;
      }
      case currentNextRoute(ConditionTypes.TYPHOID_FEVER): {
        history.push("/start/typhoid");
        break;
      }
      case currentNextRoute(ConditionTypes.RUNWAY_PLUS): {
        history.push("/start/plus");
        break;
      }
    }
  };
  return (
    <>
      <Header progress={60} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <DestinationsVisiting option="" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default DestinationType;
