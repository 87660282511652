import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ClassNameMap } from "@material-ui/styles";
import { Condition } from "@store/../@types/condition";

import { getMdiMedicationLink } from "../CaseSearch";

type ConditionsTableProps = {
  conditions: Condition[];
};

const ConditionsTable = ({ conditions }: ConditionsTableProps) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableCell>
          <b>Condition</b>
        </TableCell>
        <TableCell>
          <b>Treatment</b>
        </TableCell>
      </TableHead>
      <TableBody>
        {conditions?.map((condition) => (
          <TableRow key={condition.product.name}>
            <TableCell component="th" scope="row">
              {condition.product.name}
            </TableCell>
            <TableCell>
              {condition.suggestedMedication?.id ? (
                getMdiMedicationLink(
                  condition.suggestedMedication?.id,
                  condition.suggestedMedication?.name || condition.suggestedMedication?.id
                )
              ) : (
                <p>Pending</p>
              )}
            </TableCell>
          </TableRow>
        ))}
        {conditions?.length === 0 ? (
          <TableRow key={1}>
            <TableCell component="th" scope="row">
              Runway+
            </TableCell>
            <TableCell>Pending</TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  </TableContainer>
);
export default ConditionsTable;
