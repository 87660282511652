import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import Checkbox from "../../elements/Checkbox";
import { useSelector, useDispatch } from "react-redux";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import { userUpdate } from "../../../store/user/userSlice";

import "./Vaccines.scss";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

const Vaccines = () => {
  const dispatch = useDispatch();
  const variants = [
    "Covid-19 (at least 2 shots)",
    "TDAP (within the last 10 years)",
    "Tetanus/diptheria (within the last 10 years)",
    "Meningitis (within the last 3 years)",
    "Typhoid (within 2 years if injected or within 5 years if oral)",
    "Yellow fever",
    "Japanese encephalitis (within the past 2 years)",
    "None of the above",
  ];

  const [formState, setFormState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [captureVariants, setCaptureVariants] = useState([]);
  const [isNone, setNone] = useState(false);

  useTitle("Vaccines");
  useLoadOnTop();

  useEffect(() => {
    formState?.forEach((item, index) => {
      return item === true && captureVariants.indexOf(variants[index]) === -1
        ? setCaptureVariants((prev) => [...prev, variants[index]])
        : null;
    });
  }, [formState]);

  useEffect(() => {
    if (formState[6] === true) {
      setNone(true);
    } else {
      setNone(false);
    }
  }, [formState]);

  const history = useHistory();

  const capturedVariant = formState
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join(", ");

  const setAnswer = () => {
    if (capturedVariant && !isNone) {
      dispatch(
        questionsUpdate({
          question: "Have you received any of these additional vaccines as an adult?",
          value: capturedVariant,
          important: false,
          questionType: "general",
        })
      );
      dispatch(userUpdate({ name: "sideEffects", value: capturedVariant }));
      history.push("/problems");
    } else if (isNone) {
      dispatch(
        questionsUpdate({
          question: "Have you received any of these additional vaccines as an adult?",
          value: "None of the above",
          important: true,
          questionType: "general",
        })
      );
      dispatch(userUpdate({ name: "sideEffects", value: "None of the above" }));
      history.push("/problems");
    }
  };

  const onKeyUpHandler = (e) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={90} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">
            Have you received any of these additional vaccines as an adult?
          </Title>
          <Text size="md">Choose one or more options</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <Checkbox
                content={variant}
                checked={formState[index]}
                setChecked={() => {
                  if (index === formState.length - 1) {
                    const newState = formState.map((state) => false);
                    newState[formState.length - 1] = true;
                    setFormState(newState);
                  } else {
                    const newState = formState.map((state, i) => {
                      return i === index ? !state : state;
                    });
                    newState[formState.length - 1] = false;
                    setFormState(newState);
                  }
                }}
                num={index}
                key={`checkbox-${index}`}
              />
            ))}
          </div>
        </PageConsultation.Body>
        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default Vaccines;
