import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import ChoiceButtons from "../../elements/ChoiceButtons";
import { useDispatch } from "react-redux";
import { questionsUpdate } from "../../../store/questions/questionsSlice";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";

const TimeZone = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("Time Zone");

  // Define the current question and reason
  const currentQuestion =
    "Do you have a history of trouble adjusting your sleep schedule to time zone changes?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType: "insomnia",
      })
    );
    history.push("/conditions");
  };

  return (
    <>
      <Header progress={75} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons first="Yes" second="No" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default TimeZone;
