import "./account.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressManager from "@components/AddressManager";
import CreditCardIllustration from "@components/elements/CreditCardIlustration";
import PaymentMethodManager, { PaymentMethodManagerView } from "@components/PaymentMethodManager";
import { Button, Card } from "@runwayhealth/runway-components-react";
import { CardBrands, PaymentMethod } from "@store/../@types/billing";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { GET_PAYMENT_METHODS } from "@store/user/userActions";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { PasswordAccount } from "./Components/PasswordAccount/PasswordAccount";
import ProfileAccount from "./Components/ProfileAccount/ProfileAccount";
import BackButton from "./img/BackButton.svg";

const Account = () => {
  const dispatch = useDispatch();
  const [disableProfileForm, setDisableProfileForm] = useState(true);
  const [disablePasswordForm, setDisablePasswordForm] = useState(true);
  const [disableBillingForm, setDisableBillingForm] = useState(true);
  const [disablePaymentForm, setDisablePaymentForm] = useState(true);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const error = useSelector<RootState, string>((state) => state.ui.error);

  const stripe = useStripe();
  const elements = useElements();

  const changeDisable = (itemSelect: React.Dispatch<React.SetStateAction<boolean>>) => {
    itemSelect((prev) => !prev);
  };

  useEffect(() => {
    dispatch({ type: GET_PAYMENT_METHODS });
  }, []);

  useEffect(() => {
    if (user.data.paymentMethods.length > 1) {
      const defaultPaymentMethod = user.data.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === user.data.defaultPaymentMethod
      );
      setDefaultPaymentMethod(defaultPaymentMethod);
    }
  }, [user.data.paymentMethods]);

  return (
    <div className="account">
      {disablePasswordForm && disableBillingForm && disablePaymentForm && (
        <Card>
          <div className="account-billing">
            {!disableProfileForm && (
              <div className="account-header-form">
                <div className="back-button" onClick={() => changeDisable(setDisableProfileForm)}>
                  <img src={BackButton} alt="Go back" />
                  <span>Back</span>
                </div>
              </div>
            )}
            <div className="account-header-form">
              <div className="account-title">Profile Info</div>
              {disableProfileForm && (
                <Button onClick={() => changeDisable(setDisableProfileForm)} variant="outline">
                  Edit
                </Button>
              )}
            </div>
            <ProfileAccount
              disableProfileForm={disableProfileForm}
              user={user.data}
              error={error}
              setDisableProfileForm={setDisableProfileForm}
            />
          </div>
        </Card>
      )}
      {disableProfileForm && disableBillingForm && disablePaymentForm && (
        <Card>
          <div className="account-billing">
            {!disablePasswordForm && (
              <div className="account-header-form">
                <div className="back-button" onClick={() => changeDisable(setDisablePasswordForm)}>
                  <img src={BackButton} alt="Go back" />
                  <span>Back</span>
                </div>
              </div>
            )}
            <div className="account-header-form">
              <div className="account-title">Reset Password</div>
              {disablePasswordForm && (
                <Button onClick={() => changeDisable(setDisablePasswordForm)} variant="outline">
                  Reset
                </Button>
              )}
            </div>
            {!disablePasswordForm && (
              <PasswordAccount
                disablePasswordForm={disablePasswordForm}
                setDisablePasswordForm={setDisablePasswordForm}
                error={error}
              />
            )}
          </div>
        </Card>
      )}
      {disableProfileForm && disablePasswordForm && disableBillingForm && (
        <Card className="billing-card">
          <div className="account-billing">
            {!disablePaymentForm && (
              <div className="account-header-form">
                <div className="back-button" onClick={() => changeDisable(setDisablePaymentForm)}>
                  <img src={BackButton} alt="Go back" />
                  <span>Back</span>
                </div>
              </div>
            )}
            {disablePaymentForm && (
              <>
                <div className="account-header-form-payment-manager">
                  <div className="account-title">Billing</div>
                  <Button onClick={() => changeDisable(setDisablePaymentForm)} variant="outline">
                    Manage
                  </Button>
                </div>
                <CreditCardIllustration
                  last4={defaultPaymentMethod?.card.last4 || "XXXX"}
                  brand={defaultPaymentMethod?.card.brand || CardBrands.UNKNOWN}
                />
              </>
            )}
            {!disablePaymentForm && (
              <>
                <div className="account-header-form-payment-manager">
                  <div className="account-title">Billing</div>
                </div>
                <PaymentMethodManager
                  stripeHook={stripe}
                  stripeElementsHook={elements}
                  user={user}
                  view={PaymentMethodManagerView.PROFILE_SETTINGS}
                  cardHidden={true}
                />
              </>
            )}
          </div>
        </Card>
      )}
      {disableProfileForm && disablePasswordForm && disablePaymentForm && (
        <Card>
          <div className="account-billing">
            {!disableBillingForm && (
              <div className="account-header-form">
                <div className="back-button" onClick={() => changeDisable(setDisableBillingForm)}>
                  <img src={BackButton} alt="Go back" />
                  <span>Back</span>
                </div>
              </div>
            )}
            <div className="account-header-form">
              <div className="account-title">Your Addresses</div>
              {disableBillingForm && (
                <Button variant="outline" onClick={() => changeDisable(setDisableBillingForm)}>
                  Manage
                </Button>
              )}
            </div>
            {!disableBillingForm && <AddressManager user={user} />}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Account;
