import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import {
    Button, Feedback, FormGroupMessage, TextField, Title
} from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { FINISH_CONSULTATION } from "@store/cases/casesActions";
import { questionsUpdate } from "@store/questions/questionsSlice";

export const CLINICIAN_INITIAL_QUESTION = "Start the conversation with your physician.";

const RunwayPlusDetails = () => {
  const dispatch = useDispatch();

  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);
  const user = useSelector<RootState, User>((state) => state.user.data);

  const [detailsText, setDetailsText] = useState<string>("");
  const [emptyField, setEmptyField] = useState(false);
  useTitle("Runway Plus - Details");

  const handleClick = () => {
    setEmptyField(false);
    // If detailsText is an empty string we just skip the question from the payload.
    if (detailsText !== "") {
      dispatch(
        questionsUpdate({
          question: CLINICIAN_INITIAL_QUESTION,
          value: detailsText,
          questionType: "general",
          important: true,
        })
      );
      dispatch({
        type: FINISH_CONSULTATION,
        newCase: newCase,
        userId: user.id !== "" ? user.id : undefined,
      });
    } else {
      setEmptyField(true);
    }
  };

  return (
    <>
      <Header progress={65} />
      <PageConsultation tabIndex={1}>
        <PageConsultation.Header>
          <Title size="giant">{CLINICIAN_INITIAL_QUESTION}</Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <TextField
            as="textarea"
            value={detailsText}
            placeholder="Share additional information about your upcoming trip, travel health requests, or other medical related questions."
            onChange={(e: { target: { value: string } }) => {
              setEmptyField(false);
              setDetailsText(e.target.value);
            }}
          />
          {emptyField && (
            <FormGroupMessage>
              <Feedback size="sm" isInvalid>
                This field can't be empty.
              </Feedback>
            </FormGroupMessage>
          )}
        </PageConsultation.Body>

        <Button size="lg" iconName="ArrowRight" customWidth={440} onClick={handleClick}>
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default RunwayPlusDetails;
