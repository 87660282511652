import "./TravelersDiarrhea.scss";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

const TravelersDiarrhea = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let important = false;

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.DIARRHEA_CONSULTATION);
  }, []);
  useTitle("Traveler's Diarrhea");

  // Define the current question and reason
  const currentQuestion = "Have you ever had Traveler's Diarrhea?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value: string) => {
    if (value === "Yes") {
      important = true;
    }

    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        important,
        questionType: "diarrhea",
      })
    );
    history.push("/currently_dia");
  };
  return (
    <>
      <Header progress={5} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default TravelersDiarrhea;
