export const useIsMinor = () => {
  const isAMinor = (dateOfBirth: Date) => {
    const today = new Date();
    let date = new Date(dateOfBirth) ?? new Date();
    const differenceInMilliSeconds: number = today.getTime() - date.getTime();
    const milliSecondsInAyear = 31536000000;
    const usersAge = differenceInMilliSeconds / milliSecondsInAyear;

    if (usersAge >= 18) {
      return false;
    }

    return true;
  };
  return { isAMinor };
};
