import "./style.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PaymentMethodManagerView } from "@components/PaymentMethodManager";
import useCreatePaymentMethod from "@hooks/useCreatePaymentMethod";
import useValidatePaymentForm, * as PaymentFormValidatorMethods from "@hooks/useValidatePaymentMethod";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { PaymentFormField, PaymentMethod, PaymentMethodForm } from "@store/../@types/billing";
import * as PaymentMethodFormSlice from "@store/paymentMethodForm/paymentMethodFormSlice";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElements,
} from "@stripe/stripe-js";

import { RootEntity, RootState } from "../../../../@types/state";
import { User } from "../../../../@types/user";
import AmexSvg from "../../../../img/card/Amex.svg";
import CvcSvg from "../../../../img/card/Cvc.svg";
import Discover from "../../../../img/card/Discover.svg";
import MasterCardSvg from "../../../../img/card/MasterCard.svg";
import VisaSvg from "../../../../img/card/Visa.svg";
import { SavePayButtonContainer } from "../../../elements/PaymentMethod";

const OPTIONS = {
  style: {
    fonts: [
      {
        src: require("../../../../fonts/Gustavo/Gustavo-Regular.otf"),
        family: "Gustavo",
      },
    ],
    base: {
      iconColor: "black",
      color: "black",
      fontWeight: 500,
      fontFamily: "inherit",
      fontSize: "20px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
        fontSize: "20px",
        lineHeight: "18px",
      },
      "::placeholder": {
        color: "rgba(57, 57, 57, 0.3)",
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
    invalid: {
      iconColor: "black",
      color: "black",
    },
  },
};

const ADD_PAYMENT_FORM_TITLE = "Add a new card";

type PaymentFormProps = {
  stripeHook: Stripe | null;
  stripeElementsHook: StripeElements | null;
  save: (paymentMethod?: PaymentMethod) => void;
  view: PaymentMethodManagerView;
  paymentMethods: PaymentMethod[];
  editablePaymentMethodId?: string;
};

const PaymentForm = ({
  stripeHook,
  stripeElementsHook,
  save,
  view,
  paymentMethods,
}: PaymentFormProps) => {
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(true);
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const paymentMethodForm = useSelector<RootState, PaymentMethodForm>(
    (state) => state.paymentMethodForm
  );
  const errorState = useSelector<RootState, Array<boolean>>((state) =>
    Object.keys(state.paymentMethodForm)
      .filter((field) => field)
      .map((key) => paymentMethodForm[key as PaymentFormField].error?.flagged)
  );

  // Payment Method Hook
  const { createPaymentMethod, serializePaymentMethodResult } = useCreatePaymentMethod();

  // Validation form hook.
  const { validateForm } = useValidatePaymentForm();

  const submitPaymentMethod = async () => {
    const validForm = validateForm(paymentMethodForm);

    if (validForm) {
      const paymentMethodResult = await createPaymentMethod(
        paymentMethodForm,
        stripeHook,
        stripeElementsHook,
        CardNumberElement
      );

      if (paymentMethodResult?.paymentMethod) {
        const newPaymentMethod = serializePaymentMethodResult(paymentMethodResult.paymentMethod);
        save(newPaymentMethod);
        setDisabledButton(false);
      }
    }
  };

  useEffect(() => {
    if (user.error.message) {
      setDisabledButton(false);
    }
  }, [user.error]);

  return (
    <>
      {paymentMethods.length === 0 && view === PaymentMethodManagerView.PROFILE_SETTINGS && (
        <Text>
          You don't have any payment method registered with us. Feel free to add one using the form
          below.
        </Text>
      )}
      <Title className="form-title" as="h2" size="md-bold">
        {ADD_PAYMENT_FORM_TITLE}
      </Title>
      <div className={"payment-form"}>
        <fieldset className="FormGroup">
          {/* Card Number */}
          <div className="row g-0">
            <div className="col-md-6 card-number">
              <CardNumberElement
                onChange={(cardNumber) => {
                  dispatch(PaymentMethodFormSlice.updateCardNumber({ cardNumber }));
                }}
                onBlur={() => {
                  const fieldData = PaymentFormValidatorMethods.validateCardNumber(
                    paymentMethodForm.cardNumber.value as StripeCardNumberElementChangeEvent
                  );
                  dispatch(PaymentMethodFormSlice.updateCardNumber(fieldData));
                }}
                onFocus={() =>
                  dispatch(PaymentMethodFormSlice.updateCardNumber({ error: { flagged: false } }))
                }
                options={OPTIONS}
              />
            </div>
            <div className="col-md-6 cards-svg">
              <img src={VisaSvg} alt="" />
              <img src={MasterCardSvg} alt="" />
              <img src={AmexSvg} alt="" />
              <img src={Discover} alt="" />
            </div>
          </div>
          <div
            className={
              paymentMethodForm.cardNumber.error.flagged
                ? "error-message-payment"
                : "error-message-payment nonactive"
            }
          >
            {paymentMethodForm.cardNumber.error.flagged && (
              <p className="message-content">{paymentMethodForm.cardNumber.error.message}</p>
            )}
          </div>
          {/* End Card Number */}
          <div className="FormColumn">
            {/* Expiry Field */}
            <div className="FormRow card-data-cvc">
              <CardExpiryElement
                onChange={(expiry) => {
                  dispatch(PaymentMethodFormSlice.updateExpiry({ expiry }));
                }}
                onBlur={() => {
                  const fieldData = PaymentFormValidatorMethods.validateExpiryDate(
                    paymentMethodForm.expiry.value as StripeCardExpiryElementChangeEvent
                  );
                  dispatch(PaymentMethodFormSlice.updateExpiry(fieldData));
                }}
                onFocus={() =>
                  dispatch(PaymentMethodFormSlice.updateExpiry({ error: { flagged: false } }))
                }
                options={OPTIONS}
              />
              <div
                className={
                  paymentMethodForm.expiry.error.flagged
                    ? "error-message-payment"
                    : "error-message-payment nonactive"
                }
              >
                {paymentMethodForm.expiry.error.flagged && (
                  <p className="message-content">{paymentMethodForm.expiry.error.message}</p>
                )}
              </div>
            </div>
            {/* End Expiry Field */}
            {/* CVC Field */}
            <div className="FormRow card-data-cvc">
              <div className={"cvc"}>
                <CardCvcElement
                  onChange={(cvc) => {
                    dispatch(PaymentMethodFormSlice.updateCvc({ cvc }));
                  }}
                  onBlur={() => {
                    const fieldData = PaymentFormValidatorMethods.validateCvc(
                      paymentMethodForm.cvc.value as StripeCardCvcElementChangeEvent
                    );
                    dispatch(PaymentMethodFormSlice.updateCvc(fieldData));
                  }}
                  onFocus={() =>
                    dispatch(PaymentMethodFormSlice.updateCvc({ error: { flagged: false } }))
                  }
                  options={OPTIONS}
                  className={"cvc-input"}
                />
                <img src={CvcSvg} height="30" alt="" />
              </div>
              <div
                className={
                  paymentMethodForm.cvc.error.flagged
                    ? "error-message-payment"
                    : "error-message-payment nonactive"
                }
              >
                {paymentMethodForm.cvc.error.flagged && (
                  <p className="message-content">{paymentMethodForm.cvc.error.message}</p>
                )}
              </div>
            </div>
          </div>
          {/* End CVC Field */}
          {/* Name on card Field */}
          <div className="FormRow">
            <input
              type="text"
              autoComplete="off"
              className={"payment-input"}
              placeholder={"Name on card"}
              name={"name"}
              onChange={(e) => {
                const { target } = e;
                dispatch(PaymentMethodFormSlice.updateNameOnCard({ nameOnCard: target.value }));
              }}
              onBlur={() => {
                const fieldData = PaymentFormValidatorMethods.validateName(
                  paymentMethodForm.nameOnCard.value as string
                );
                dispatch(PaymentMethodFormSlice.updateNameOnCard(fieldData));
              }}
              onFocus={() =>
                dispatch(PaymentMethodFormSlice.updateNameOnCard({ error: { flagged: false } }))
              }
            />
          </div>
          <div
            className={
              paymentMethodForm.nameOnCard.error.flagged
                ? "error-message-payment"
                : "error-message-payment nonactive"
            }
          >
            {paymentMethodForm.nameOnCard.error.flagged && (
              <p className="message-content">{paymentMethodForm.nameOnCard.error.message}</p>
            )}
          </div>
          {/* End Name on card Field */}
          {/* Zip Code */}
          <p className="checkout-email-label">ZIP CODE</p>
          <div className="FormColumn">
            <div className="FormRow state">
              <input
                type="number"
                className={"payment-input"}
                placeholder={"Zip code"}
                name={"zip_code"}
                autoComplete="off"
                onChange={(e) => {
                  setDisabledButton(false);
                  const { target } = e;
                  dispatch(PaymentMethodFormSlice.updateZip({ zipCode: target.value }));
                }}
                onInput={({ target }: React.FormEvent<HTMLInputElement>) =>
                  ((target as HTMLInputElement).value = (target as HTMLInputElement).value.slice(
                    0,
                    10
                  ))
                }
                onBlur={() => {
                  const fieldData = PaymentFormValidatorMethods.validateZip(
                    paymentMethodForm.zipCode.value as string
                  );
                  dispatch(PaymentMethodFormSlice.updateZip(fieldData));
                }}
                onFocus={() =>
                  dispatch(PaymentMethodFormSlice.updateZip({ error: { flagged: false } }))
                }
              />
              <div
                className={
                  paymentMethodForm.zipCode.error.flagged
                    ? "error-message-payment"
                    : "error-message-payment nonactive"
                }
              >
                {paymentMethodForm.zipCode.error.flagged && (
                  <p className="message-content">{paymentMethodForm.zipCode.error.message}</p>
                )}
              </div>
            </div>
          </div>
          {/* End Zip Code */}
        </fieldset>
        <SavePayButtonContainer>
          {(paymentMethods.length > 0 || view === PaymentMethodManagerView.PROFILE_SETTINGS) && (
            <Button
              size="lg"
              isLoading={user.isPending}
              disabled={disabledButton || user.isPending || errorState.includes(true)}
              onClick={submitPaymentMethod}
              customWidth={400}
            >
              Save Payment Method
            </Button>
          )}
        </SavePayButtonContainer>
      </div>
    </>
  );
};

export default PaymentForm;
