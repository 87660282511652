import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
import "./index.scss";
import "./fonts/Gustavo/Gustavo-Regular.otf";

import amplitude from "amplitude-js";
import { ConnectedRouter } from "connected-react-router";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createGlobalStyle } from "styled-components";

import {
  color,
  font,
  GustavoBoldWoff,
  GustavoBoldWoff2,
  GustavoMediumWoff,
  GustavoMediumWoff2,
  GustavoRegularWoff,
  GustavoRegularWoff2,
  NotoSansBoldTtf,
  NotoSansMediumTtf,
  NotoSansRegularTtf,
  resetStyles,
} from "@runwayhealth/runway-components-react";
import * as Sentry from "@sentry/react";

import App from "./App";
import { history, persistor, store } from "./store";
import { ENV } from "./utils/constantsEnv";

const environment = ENV || process.env.NODE_ENV;
const tracesSampleRate = environment === "production" ? 0.5 : 0;
const replaysSessionSampleRate = environment === "production" ? 0.5 : 0.1;

// Init Sentry
Sentry.init({
  environment,
  dsn: "https://ca23d257872e404fb3cbd0fc1df4cbd6@o1142362.ingest.sentry.io/6201327",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

// Init amplitude
amplitude.getInstance().init("a7be61971b4c552fce287edd57348d5e");

// Init GTM
TagManager.initialize({ gtmId: "GTM-P2FW3LL" });

// Init Posthog
const options = {
  api_host: "https://us.i.posthog.com",
};

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: ${font.family.highlight.bold};
  src:
  url(${GustavoBoldWoff2}) format('woff2'),
  url(${GustavoBoldWoff}) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: ${font.family.highlight.medium};
  src:
  url(${GustavoMediumWoff2}) format('woff2'),
  url(${GustavoMediumWoff}) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: ${font.family.highlight.regular};
  src:
  url(${GustavoRegularWoff2}) format('woff2'),
  url(${GustavoRegularWoff}) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: ${font.family.base.bold};
  src:
  url(${NotoSansBoldTtf}) format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: ${font.family.base.medium};
  src:
  url(${NotoSansMediumTtf}) format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: ${font.family.base.regular};
  src:
  url(${NotoSansRegularTtf}) format('truetype');
  font-style: normal;
  font-weight: normal;
}

body {
  ${resetStyles}
  background-color: ${color.brand.secondary.lighter};
  font-family: ${font.family.highlight.regular}, ${font.family.fallback};
  -webkit-font-smoothing: antialiased;
}
input {
  background: inherit;
}
`;

ReactDOM.render(
  <PostHogProvider
    apiKey={
      process.env.REACT_APP_ENV === "development"
        ? "phc_to9p6iGr7nPVW8WSthR4Qz4Cag6SHWtia3Zto7yCKhx"
        : "phc_Juj8RyozDdyjOFmevbU3dw9jVoOdudoAWrxdn95eTu8"
    }
    options={options}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </PostHogProvider>,
  document.getElementById("root")
);
