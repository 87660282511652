import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressManager from "@components/AddressManager";
import { Case } from "@store/../@types/case";
import { RootState, UserSearchData } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { getCasesByUser, searchUserById, sendAdminOrder } from "@store/admin/adminAction";
import { UPDATE_CASE } from "@store/cases/casesActions";
import { userObject } from "@store/user/userSlice";

import { UserDTO } from "../DTOs/UserDTO";

type CaseAddressProps = {
  case_: Case;
  closeModal: () => void;
};

const CaseAddress = ({ case_, closeModal }: CaseAddressProps) => {
  const userData = useSelector<RootState, UserSearchData>((state) => state.admin.users);
  const [userResult, setUserResult] = useState<User>(userObject.data);
  const dispatch = useDispatch();
  const setCaseShippingAddress = (addressId: string) => {
    dispatch({
      type: UPDATE_CASE,
      caseId: case_.id,
      updateBody: {
        shippingAddress: {
          id: addressId,
        },
      },
    });
    closeModal();

    setTimeout(() => {
      // Re send order
      if (case_.CurexaOrder?.order_id && case_.CurexaOrder?.order_id !== "") {
        dispatch(
          sendAdminOrder({
            orderId: case_.CurexaOrder.id,
            referenceId: case_.CurexaOrder.order_id,
          })
        );
      }
    }, 1000);
  };

  useEffect(() => {
    // Get user's cases
    dispatch(getCasesByUser(case_.UserId));

    // Always search user on CaseAddress mount
    dispatch(searchUserById({ id: case_.UserId }));

    if (userData.id !== "") {
      const userModel = new UserDTO(userData);
      setUserResult(userModel);
    }
  }, [userData.id]);

  return (
    <>
      <h2>Case: {case_.caseId}</h2>
      <hr></hr>
      <h3>Selected Shipping Address</h3>
      <hr></hr>
      <p>
        <b>Street: </b>
        {case_.shippingAddress?.street}
      </p>
      <p>
        <b>Secondary:</b> {case_.shippingAddress?.secondary}
      </p>
      <p>
        <b>City: </b>
        {case_.shippingAddress?.city}
      </p>
      <p>
        <b>State: </b>
        {case_.shippingAddress?.state}
      </p>
      <p>
        <b>Postal Code: </b>
        {case_.shippingAddress?.postalCode}
      </p>
      <p>
        <b>Country:</b> {case_.shippingAddress?.country}
      </p>
      <hr></hr>
      {userResult.id !== "" && (
        <AddressManager
          user={{
            isPending: false,
            data: userResult,
            error: {},
          }}
          openForm={false}
          confirmSelectedAddress={setCaseShippingAddress}
          isCheckout
        />
      )}
    </>
  );
};

export default CaseAddress;
