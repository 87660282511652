import styled from "styled-components";

import { Card, color, FormGroup } from "@runwayhealth/runway-components-react";

export const AddressContainer = styled.div<{ $selected?: boolean; $isCheckout?: boolean }>`
  border: 2px solid
    ${(props) => (props.$selected ? color.brand.primary.medium : color.brand.secondary.light)};
  background: ${(props) =>
    props.$selected ? color.brand.primary.lighter : color.neutral.lightest};
  border-radius: 16px;
  padding: 22px 24px 20px 24px;
  margin-bottom: 10px;
  ${(props) => props.$isCheckout && "cursor: pointer;"}
`;

export const AddressBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .add-new-address {
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      margin-bottom: 0px;
    }
  }
  h3 {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 10px;
  }
  p {
    line-height: 1rem;
  }
`;

export const EditDeleteButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DefaultTag = styled.div`
  background: ${color.brand.primary.light};
  padding: 2px 6px 3px 6px;
  width: 85px;
  border-radius: 4px;
  margin-bottom: 10px;
  h3 {
    font-size: 1rem;
    color: ${color.brand.primary.darkest};
    font-weight: bold;
    margin-bottom: 0px;
  }
`;

export const AddressFormCard = styled(Card)<{ $enableCardDecor?: boolean }>`
  text-align: left !important;
  ${(props) =>
    !props.$enableCardDecor &&
    `
    box-shadow: none;   
    padding: 0px;
  `}
`;

export const ConfirmButtonWrapper = styled.div`
  text-align: center;
  padding-top: 25px;
`;

export const AddressFormGroup = styled(FormGroup)`
  margin-bottom: 5px;
`;
