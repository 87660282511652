import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

type IConsultationProps = {
  consultationName: string;
};

const Infection = () => {
  const dispatch = useDispatch();
  const { consultationName } = useParams<IConsultationProps>();

  useEffect(() => {
    sessionStorage.setItem("current_consultation", consultationName);
  }, []);
  useTitle(consultationName);

  const history = useHistory();

  // Define the current question and reason
  const currentQuestion = `Do you have a history of ${
    consultationName === ConditionTypes.VAGINAL_YEAST_INFECTION ? "yeast" : "urinary tract"
  } infections?`;
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType:
          consultationName === ConditionTypes.VAGINAL_YEAST_INFECTION
            ? "vaginalYeastInfection"
            : "urinaryTractInfection",
      })
    );

    if (consultationName === ConditionTypes.VAGINAL_YEAST_INFECTION) {
      if (value === "No") {
        history.push("/conditions");
        return;
      }
      // Redirect to fluconazon question
      history.push("/fluconazole");
    } else if (consultationName === ConditionTypes.URINARY_TRACT_INFECTION) {
      // Redirect to nitrofurantoin questions
      if (value === "No") {
        history.push("/immunocompromised");
        return;
      }
      history.push("/nitrofurantoin");
    }
  };

  return (
    <>
      <Header progress={4} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default Infection;
