import update from "immutability-helper";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  pending: true,
  links: {
    first: "",
    last: "",
    prev: null,
    next: null,
  },
  meta: {
    current_page: 0,
    from: 1,
    last_page: 0,
    links: [],
    path: "",
    per_page: 15,
  },
};

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessagesPending: (state, action) => {
      return update(state, {
        pending: {
          $set: true,
        },
      });
    },
    setLastPage: (state, action) => {
      return update(state, {
        pending: {
          $set: false,
        },
        meta: {
          last_page: {
            $set: action.payload,
          },
        },
      });
    },
    pushMessages: (state, action) => {
      // If a message in payload does not contain data property,
      // then is considered created by the user or by the socket channel.
      if (!action.payload.data) {
        return update(state, {
          pending: {
            $set: false,
          },
          data: {
            $push: [action.payload],
          },
        });
        // Else messages are considered as incoming.
      } else {
        const messagesPayloadIds = new Set(state.data.map((message) => message.id));
        const incomingMessages = action.payload.data.filter((message) => {
          const newMessage = !messagesPayloadIds.has(message.id);
          if (newMessage) {
            return message;
          }
        });

        // Sort messages by date.
        const sortedMessages = state.data
          .concat(incomingMessages)
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

        return update(state, {
          pending: {
            $set: false,
          },
          meta: {
            $set: action.payload.meta,
          },
          data: {
            $set: state.data.length === 0 ? action.payload.data : sortedMessages,
          },
        });
      }
    },
    sendMessageSuccess: (state, action) => {
      const messageIndex = state.data.findIndex((message) => message.isPending);
      return update(state, {
        data: {
          [messageIndex]: {
            id: {
              $set: action.payload,
            },
            isPending: {
              $set: false,
            },
          },
        },
      });
    },
    clearMessages: () => {
      return initialState;
    },
  },
});

export const { setMessagesPending, pushMessages, setLastPage, sendMessageSuccess, clearMessages } =
  messageSlice.actions;

export default messageSlice.reducer;
