import "./AnyProblems.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import {
  Button,
  FormGroup,
  Label,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

import { userUpdate } from "../../../store/user/userSlice";

const AnyProblems = () => {
  const [problems, setProblems] = useState("");
  const [isProblems, setIsProblems] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle("Medical Problems");

  // Define the current question and reason
  const currentQuestion = "Are you currently being treated for any medical conditions?";
  const currentReason = "We ask this to avoid medical interactions.";
  const currentPlaceholder = "Include your diagnosis and treatment.";

  const setNo = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value: value,
        important: false,
        questionType: "general",
      })
    );
    history.push("/medications");
  };

  const setAnswer = () => {
    if (problems.length > 0) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: problems,
          important: true,
          questionType: "general",
        })
      );
      dispatch(userUpdate({ name: "medicalConditions", value: problems }));
      history.push("/medications");
    }
  };

  const YesAnswer = () => {
    setIsProblems(true);
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  const handleProblem = (e: any) => {
    setProblems(e.target.value);
  };
  return (
    <>
      <Header progress={95} />
      <PageConsultation className="rwa-page-any-problems" tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            setAnswer={YesAnswer}
            setNo={setNo}
            problems={true}
            isActive={isProblems}
          />

          {isProblems && (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <FormGroup className="rwa-page-any-problems-form-group">
                  <Label>Your conditions</Label>
                  <TextField
                    as="textarea"
                    value={problems}
                    onChange={handleProblem}
                    placeholder={currentPlaceholder}
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </PageConsultation.Body>

        {isProblems && (
          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={setAnswer}
            disabled={problems.trim().length === 0}
          >
            Next
          </Button>
        )}
      </PageConsultation>
    </>
  );
};

export default AnyProblems;
