import "./Insomnia.scss";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";
import NextButton from "../../elements/NextButton";
import RadioButton from "../../elements/RadioButton";

const Insomnia = () => {
  const dispatch = useDispatch();
  const variants = ["Often", "On occasion", "Rarely", "Never"];
  const [state, setState] = useState([false, false, false, false]);

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.INSOMNIA_CONSULTATION);
  }, []);

  const history = useHistory();
  useTitle("Insomnia");
  useLoadOnTop();

  const capturedVariant = state
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join("");

  // Define the current question and reason
  const currentQuestion = "Do you have difficulty falling asleep?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = () => {
    if (capturedVariant) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: capturedVariant,
          questionType: "insomnia",
        })
      );
      history.push("/staying_asleep");
    }
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={5} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            <RadioButton content={variants[0]} checked={state[0]} setChecked={setState} num={0} />
            <RadioButton content={variants[1]} checked={state[1]} setChecked={setState} num={1} />
            <RadioButton content={variants[2]} checked={state[2]} setChecked={setState} num={2} />
            <RadioButton content={variants[3]} checked={state[3]} setChecked={setState} num={3} />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default Insomnia;
