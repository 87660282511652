import { useHistory } from "react-router-dom";
import Header from "../../elements/Header";
import ChoiceButtons from "../../elements/ChoiceButtons";
import "./RoutineVaccines.scss";
import { useDispatch } from "react-redux";
import { questionsUpdate } from "../../../store/questions/questionsSlice";
import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";

const RoutineVaccines = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let important = false;
  useTitle("Routine Vaccinations");

  // Define the current question and reason
  const currentQuestion = "Did you receive routine vaccines as a child?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value) => {
    if (value === "No") {
      important = false;
    }

    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        important,
        questionType: "general",
      })
    );
    history.push("/adult_vaccines");
  };
  return (
    <>
      <Header progress={60} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons first="Yes" second="No" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default RoutineVaccines;
