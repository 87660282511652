import "./confirmID.scss";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { PhotoPrivacyFooter } from "@components/elements/PhotoPrivacyFooter";
import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import ModalWindow from "@elements/ModalWindow";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";

type IConfirmIDProps = {
  encodedPayload: string;
};

const ConfirmID = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { encodedPayload } = useParams<IConfirmIDProps>();
  const [photoIdURL, setPhotoIdURL] = useState("");
  const [isRunwayPlus, setIsRunwayPlus] = useState(false);
  useTitle("Confirm ID");

  const confirmID = () => {
    if (isRunwayPlus) {
      history.push("/plus_details");
    } else {
      // TODO: Continue with the flow.
    }
  };

  useEffect(() => {
    const decodedPayload = JSON.parse(window.atob(encodedPayload));
    setPhotoIdURL(decodedPayload.photoIdURL);
    setIsRunwayPlus(decodedPayload.isRunwayPlus);
    setIsLoading(false);
  }, []);

  return (
    <>
      <Header progress={95} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">Is this still your photo ID?</Title>
          <Text size="md">
            Your healthcare professional will use this photo to verify your identity. Please confirm
            if it is still valid; otherwise, resubmission is required.
          </Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          {!isLoading && (
            <img
              style={{ width: "50%" }}
              src={photoIdURL}
              alt={"Photo ID"}
              className="ph-no-capture"
            />
          )}
        </PageConsultation.Body>
        <div className="button-wrapper">
          <Button size="lg" iconName="ArrowRight" customWidth={440} onClick={confirmID}>
            Yes
          </Button>
          <Button size="lg" customWidth={440} onClick={() => setModal(true)} variant="outline">
            Submit New Photo ID
          </Button>
        </div>
        <PhotoPrivacyFooter />
        {modal && <ModalWindow open={modal} close={setModal} next={false} minor={false} />}
      </PageConsultation>
    </>
  );
};

export default ConfirmID;
