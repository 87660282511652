import "./medicationsTaken.scss";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useIsMinor } from "@hooks/useIsMinor";
import { useTitle } from "@hooks/useTitle";
import {
    Button, FormGroup, Label, Text, TextField, Title
} from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { ConditionTypes } from "@store/../@types/condition";
import { RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { FINISH_CONSULTATION } from "@store/cases/casesActions";
import { questionsUpdate } from "@store/questions/questionsSlice";
import { userUpdate } from "@store/user/userSlice";

const MedicationsTaken = () => {
  const { isAMinor } = useIsMinor();
  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);
  const cases = useSelector<RootState, RootEntity<Case>[]>((state) => state.case.cases.data);
  const user = useSelector<RootState, User>((state) => state.user.data);
  const [medications, setMedications] = useState("");
  const [isTakingMedications, setIsTakingMedications] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle("Current Medications");

  // Define the current question and reason
  const currentQuestion = "Are you currently taking any medications?";
  const currentReason = "We ask this to avoid medical interactions.";
  const currentPlaceholder = "Please be specific.";

  const setNo = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value: "None",
        important: false,
        questionType: "general",
      })
    );

    dispatch(userUpdate({ name: "current_medications", value: "None" }));
    pushToNextRoute();
  };

  const setAnswer = () => {
    if (medications.length > 0) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: medications,
          important: true,
          questionType: "general",
        })
      );

      dispatch(userUpdate({ name: "current_medications", value: medications }));
      pushToNextRoute();
    }
  };

  const YesAnswer = () => {
    setIsTakingMedications(true);
  };

  const pushToNextRoute = () => {
    if (cases.length === 0) {
      history.push("/marketing-channels");
    } else if (isAMinor(user.date_of_birth)) {
      history.push("/parental_consent");
    } else {
      const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
      if (consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? newCase.conditions.length === 0) {
        history.push("/plus_details");
      } else {
        // Else finish consultation.
        dispatch({
          type: FINISH_CONSULTATION,
          newCase: newCase,
          userId: user.id !== "" ? user.id : undefined,
        });
      }
    }
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    // Check if the currently focused element is a textarea
    if (document.activeElement?.tagName === "TEXTAREA") {
      return;
    }

    if (e.key === "Enter") {
      setAnswer();
    }
  };

  const collectMedications = (e: any) => {
    setMedications(e.target.value);
  };
  return (
    <>
      <Header progress={95} />
      <PageConsultation
        className="rwa-page-medications-taken"
        tabIndex={1}
        onKeyUp={onKeyUpHandler}
      >
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            setAnswer={YesAnswer}
            setNo={setNo}
            problems={true}
            isActive={isTakingMedications}
          />

          {isTakingMedications && (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <FormGroup className="rwa-page-any-problems-form-group">
                  <Label>Your medications</Label>
                  <TextField
                    as="textarea"
                    value={medications}
                    onChange={collectMedications}
                    placeholder={currentPlaceholder}
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </PageConsultation.Body>

        {isTakingMedications && (
          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={setAnswer}
            disabled={medications.trim().length === 0}
          >
            Next
          </Button>
        )}
      </PageConsultation>
    </>
  );
};

export default MedicationsTaken;
