import { select, SelectEffect } from "redux-saga/effects";
import Stripe from "stripe";

import { PaymentMethodForm } from "./billing";
import { Case, Order, Question } from "./case";
import { ConditionTypes } from "./condition";
import { Address, User } from "./user";

export enum ErrorEvents {
  ACCESS_DENIED = "Access Denied",
  SEND_ORDER = "sendOrder",
  PAY_ORDER = "payOrder",
  SEND_CASE = "sendCase",
  PAY_CASE = "payCase",
  LOGIN = "login",
  NOT_FOUND = "Not Found",
  BAD_REQUEST = "Bad Request",
  SERVER_ERROR = "Server Error",
  PENDING_INVOICE = "Case is not paid.",
  ERR_NETWORK = "Network Error",
  UPSELL_FAILURE = "Upsell Failure",
}

export enum ServicesTypes {
  VISIT_FEE = "Visit Fee",
  SHIPPING_FEE = "Shipping Fee",
}

export interface ProductMetadata {
  name: string;
  // medication_1, medication_2:"priceid_2323" ...
  [key: string]: string;
}

export interface Product {
  id: string;
  name: ConditionTypes | ServicesTypes;
  metadata?: ProductMetadata;
  units: number;
}
export interface RunwayPrice {
  id: string;
  product: Product;
  unit_amount: number;
}

/**
 * A RootEntity is an interface that represents
 * the redux state pattern, which behavior repeats among
 * the different reducers, considering that a state may always:
 * - Have a mutation in progress (isPending)
 * - Contain a data presentation layer.
 * - Hold mutation error details.
 */
export interface RootEntity<T> {
  isPending: boolean;
  data: T;
  error: {
    message?: string;
    event?: string;
  };
}

export interface CaseState {
  newCase: RootEntity<Case>;
  cases: RootEntity<RootEntity<Case>[]>;
}

export interface PricesState {
  case: RootEntity<RunwayPrice>;
  shipping: RootEntity<Stripe.ShippingRate[]>;
  prescriptions: RootEntity<RunwayPrice[]>;
}
export interface QuestionsStateData {
  general: Question[];
  diarrhea: Question[];
  malaria: Question[];
  altitude: Question[];
  motion: Question[];
  insomnia: Question[];
}

export interface RouterData {
  location: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
    query: {
      [key: string]: string;
    };
  };
}

export interface Partner {
  clientKey: string;
  name: string;
  transactionsCount: number;
}

export interface Transaction {
  id: string;
  userEmail: string;
  invoiceId?: string;
  PartnerClientKey: string;
}

export interface QuestionnaireAnswer {
  question: string;
  answer: string;
  type: string;
}

export interface UserSearchData {
  id: string;
  firstName: string;
  addresses: Address[];
  lastName: string;
  email: string;
  stripeId: string;
  mdiId: string;
  address: string;
  phoneNumer: string;
  dob: string;
}

export interface Credentials {
  clientKey: string;
  clientSecret: string;
}

export interface PromoCode {
  promoCodeId: string;
  times_redeemed: number;
  first_time_redeemed: Date;
  last_time_redeemed: Date;
  code: string;
}

export interface Redemption {
  createdAt: Date;
  User: {
    email: string;
  };
}

export interface AdminState {
  partners: Partner[];
  users: UserSearchData;
  credentials: Credentials;
  transactions: Transaction[];
  cases: Case[];
  orders: Order[];
  promoCodes: PromoCode[];
  redemptions: Redemption[];
  cancelationError: boolean;
  resubmitError: boolean;
  caseShippingError: boolean;
  orderShippingError: boolean;
  orderSyncError: boolean;
}

export interface RootState {
  router: RouterData;
  user: RootEntity<User>;
  case: CaseState;
  price: PricesState;
  questions: QuestionsStateData;
  prescriptionPrices: RunwayPrice[];
  paymentMethodForm: PaymentMethodForm;
  messages: {};
  ui: {
    error: string;
    loading: true;
    home_address_status: boolean;
    notification: {
      message: boolean;
    };
  };
  admin: AdminState;
}

export interface DateFieldValue {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
}

export function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}
