import { PaymentMethodForm } from "@store/../@types/billing";
import { CardNumberElementComponent } from "@stripe/react-stripe-js";
import {
  PaymentMethod as StripePaymentMethod,
  PaymentMethodResult,
  Stripe,
  StripeElements,
} from "@stripe/stripe-js";
import { PaymentMethodDTO } from "@utils/dtos/PaymentMethodDTO";

const useCreatePaymentMethod = () => {
  const createPaymentMethod = async (
    paymentMethodForm: PaymentMethodForm,
    stripe: Stripe | null,
    elements: StripeElements | null,
    cardComponent: CardNumberElementComponent
  ): Promise<PaymentMethodResult | undefined> => {
    const card = elements?.getElement(cardComponent) || { token: "" };
    const newPaymentMethodResult = await stripe?.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: paymentMethodForm.nameOnCard.value as string,
        address: {
          postal_code: paymentMethodForm.zipCode.value as string,
        },
      },
    });
    return newPaymentMethodResult;
  };

  const serializePaymentMethodResult = (stripePaymentMethod: StripePaymentMethod) => {
    const paymentMethod = new PaymentMethodDTO(stripePaymentMethod);
    return paymentMethod;
  };

  return { createPaymentMethod, serializePaymentMethodResult };
};

export default useCreatePaymentMethod;
