import "./case.scss";

import moment from "moment";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  AlertBanner,
  Button,
  Card,
  Label,
  Link,
  Text,
  Title,
} from "@runwayhealth/runway-components-react";
import {
  Case as ICase,
  CaseStatuses,
  OrderCaseStatuses,
  OrderStatuses,
} from "@store/../@types/case";
import { ErrorEvents, RootEntity } from "@store/../@types/state";
import * as CasesActions from "@store/cases/casesActions";
import * as casesSlice from "@store/cases/casesSlice";
import { REQUEST_CASE_PRICE, REQUEST_SHIPPING_PRICE } from "@store/prices/pricesActions";
import { OVERNIGHT_SHIPPING_ID, PRIORITY_SHIPPING_ID, VISIT_FEE_ID } from "@utils/constantsEnv";

import TruckSvg from "../../../../../../img/orders/truck.svg";
import Condition from "../Conditions/component/Condition";
import RunwayPlusNotice from "../Conditions/component/RunwayPlus/RunwayPlusNotice";
import Summary from "../Order/Summary";

type Props = {
  case_: RootEntity<ICase>;
  customPharmacy?: string;
};

const getTrackingLink = (trackingNumber: string, carrier: string) =>
  carrier === "ups"
    ? `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}/trackdetails`
    : `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`;

const Case = ({ case_, customPharmacy }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Pending actions.
  const [isPendingOrderPayment, setIsPendingOrderPayment] = useState(false);
  const [isPendingCaseSend, setIsPendingCaseSend] = useState(false);
  const [isPendingCasePayment, setIsPendingCasePayment] = useState(false);
  const [pendingAction, setPendingAction] = useState("");

  // Order processing.
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const [caseStatus, setCaseStatus] = useState("");

  const [isCustomPharmacy, setIsCustomPharmacy] = useState(false);

  // Collects case status information.
  // We merge case and order status to show a single status information label.
  useEffect(() => {
    const orderStatus =
      case_.data.CurexaOrder?.replacement?.status ?? case_.data.CurexaOrder?.status;

    const shipped =
      case_.data.case_status === CaseStatuses.COMPLETED &&
      orderStatus === OrderStatuses.OUT_FOR_DELIVERY;

    const completed =
      case_.data.case_status === CaseStatuses.COMPLETED &&
      (orderStatus === OrderStatuses.COMPLETED || !case_.data.conditions[0]);

    const cancelled =
      case_.data.case_status === CaseStatuses.CANCELLED || orderStatus === OrderStatuses.CANCELLED;

    let caseStatus = OrderCaseStatuses.PROCESSING;

    if (shipped) {
      caseStatus = OrderCaseStatuses.SHIPPED;
    }

    if (completed) {
      caseStatus = OrderCaseStatuses.COMPLETE;
    }

    if (cancelled) {
      caseStatus = OrderCaseStatuses.CANCELLED;
    }

    setCaseStatus(caseStatus);

    const pharmacy = customPharmacy !== "" && customPharmacy !== null;
    setIsCustomPharmacy(pharmacy);
  }, [case_.data.case_status, case_.data.CurexaOrder?.status]);

  // Collects case and order pending actions.
  useEffect(() => {
    const isPendingOrderPayment =
      case_.data.CurexaOrder?.id !== null &&
      case_.data.CurexaOrder?.status === null &&
      case_.data.case_status === CaseStatuses.COMPLETED;

    const isPendingCaseSend = case_.data.caseId === null && case_.data.invoiceId !== null;

    const isPendingCasePayment = case_.data.invoiceId === null;

    setIsPendingOrderPayment(isPendingOrderPayment);
    setIsPendingCaseSend(isPendingCaseSend);
    setIsPendingCasePayment(isPendingCasePayment);

    if (isPendingOrderPayment) {
      setPendingAction("Complete purchase");
    }
    if (isPendingCasePayment) {
      setPendingAction("Proceed to checkout");
    }
  }, [case_.data.CurexaOrder?.status]);

  // Sends the order if paid and is processing true.
  useEffect(() => {
    if (isOrderProcessing && case_.data.CurexaOrder?.status) {
      dispatch({
        type: CasesActions.SEND_ORDER,
        caseId: case_.data.id,
        orderId: case_.data.CurexaOrder?.order_id,
      });
      setIsOrderProcessing(false);
    }
  }, [case_.data.CurexaOrder?.status, isOrderProcessing]);

  // Checks order sent successfully.
  useEffect(() => {
    if (case_.data.CurexaOrder?.status !== null) {
      setIsOrderProcessing(false);
      setPendingAction("");
    }
  }, [case_.data.CurexaOrder?.status]);

  // Checks case mutation errors.
  useEffect(() => {
    if (case_?.error?.event) {
      switch (case_.error?.event) {
        case ErrorEvents.SEND_ORDER:
          callToast(`Could not send order. Error: ${case_.error.message}`);
          break;
        case ErrorEvents.PAY_ORDER:
          callToast(`Could not pay order. Error: ${case_.error.message}`);
          break;
        case ErrorEvents.SEND_CASE:
          callToast(`Could not send case. Error: ${case_.error.message}`);
          break;
        case ErrorEvents.PENDING_INVOICE:
          dispatch(casesSlice.setCasePendingPayment({ caseId: case_.data.id }));
          setIsPendingCasePayment(true);
          setPendingAction("Go to checkout");
          break;
        default:
          callToast(`Unknown error: ${case_.error.message}`);
      }
    }
  }, [case_.error]);

  const callToast = (message: string) => {
    toast(message, {
      position: "top-center",
      autoClose: 3000,
      closeButton: true,
      hideProgressBar: true,
    });
  };

  const completePendingAction = () => {
    setIsOrderProcessing(true);
    if (isPendingOrderPayment) {
      dispatch({
        type: CasesActions.PAY_ORDER,
        caseId: case_.data.id,
        orderId: case_.data.CurexaOrder?.order_id,
      });
    }

    if (isPendingCaseSend) {
      dispatch({
        type: CasesActions.SEND_CASE,
        newCase: case_.data,
      });
    }

    if (isPendingCasePayment) {
      // Load prices.
      dispatch({
        type: REQUEST_CASE_PRICE,
        priceId: VISIT_FEE_ID,
      });
      dispatch({
        type: REQUEST_SHIPPING_PRICE,
        shippingRatesIds: [PRIORITY_SHIPPING_ID, OVERNIGHT_SHIPPING_ID],
      });
      dispatch(casesSlice.loadCase(case_));
      history.push("/checkout");
    }
  };

  return (
    <Card>
      <Container>
        <Row>
          <Col xs={12} sm={12} lg={4}>
            <Title>
              {case_.data.caseId !== null
                ? `Order #${case_.data.caseId?.split("-")[0]}`
                : `Case #${case_.data.id}`}
            </Title>
          </Col>
          <Col className="centered-text" xs={12} sm={12} lg={2}>
            {case_.data.caseId !== null && (
              <div className="">
                <Label>{isCustomPharmacy ? case_.data.case_status : caseStatus}</Label>
              </div>
            )}
          </Col>
          <Col className="centered-text" xs={12} sm={12} lg={5}>
            {case_.data?.CurexaOrder?.tracking_number &&
              (caseStatus === OrderCaseStatuses.SHIPPED ||
                caseStatus === OrderCaseStatuses.COMPLETE) && (
                <div className="tracking-number">
                  <span className="tracking-order-carrier-name">
                    <img src={TruckSvg} alt="" />{" "}
                    {case_.data.CurexaOrder?.replacement?.carrier ??
                      case_.data.CurexaOrder?.carrier}{" "}
                    :
                  </span>
                  <Link
                    href={getTrackingLink(
                      case_.data.CurexaOrder?.replacement?.tracking_number ??
                        case_.data.CurexaOrder?.tracking_number,
                      case_.data.CurexaOrder?.replacement?.carrier ??
                        case_.data.CurexaOrder?.carrier
                    )}
                    isBlank
                  >
                    {case_.data.CurexaOrder?.replacement?.tracking_number ??
                      case_.data.CurexaOrder?.tracking_number}
                  </Link>
                </div>
              )}
            {isCustomPharmacy && (
              <div className="local-pharmacy-advice">
                {/* <span>
                <img src={TrackingSvg} alt="" /> {case_.data.CurexaOrder.carrier}
              </span> */}
                <Text>Contact your local pharmacy</Text>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <hr></hr>
      {isCustomPharmacy && caseStatus !== OrderCaseStatuses.CANCELLED && (
        <AlertBanner variant="info" isRounded>
          <AlertBanner.Message>
            Runway cannot guarantee the price or availability of medications not fulfilled by
            Runway.
          </AlertBanner.Message>
        </AlertBanner>
      )}
      {isPendingOrderPayment && (
        <AlertBanner variant="info" isRounded>
          <AlertBanner.Message>
            Medication has been prescribed by your physician. See the summary below to confirm your
            payment and have your medication delivered.
          </AlertBanner.Message>
        </AlertBanner>
      )}
      {caseStatus === OrderCaseStatuses.CANCELLED && (
        <AlertBanner variant="error" isRounded>
          <AlertBanner.Message>
            No medications were prescribed by your physician. Your order has been cancelled and we
            have refunded your consultation fee.
          </AlertBanner.Message>
        </AlertBanner>
      )}
      {isPendingCasePayment && (
        <AlertBanner variant="info" isRounded>
          <AlertBanner.Message>Your consultation is ready for checkout.</AlertBanner.Message>
        </AlertBanner>
      )}
      <Container>
        <Row>
          {case_.data.conditions.map((condition) => {
            return (
              <Condition
                key={condition.suggestedMedication?.id}
                condition={condition}
                caseStatus={case_.data.case_status || CaseStatuses.PROCESSING}
              />
            );
          })}
        </Row>
      </Container>
      {isPendingOrderPayment && <Summary case_={case_.data} />}
      {case_.data.conditions.length === 0 && case_.data.case_status !== CaseStatuses.COMPLETED ? (
        <RunwayPlusNotice caseId={case_.data.caseId} />
      ) : (
        <div>
          {" "}
          {case_.data.conditions.length === 0 && (
            <p>Case has been completed with no prescriptions.</p>
          )}
        </div>
      )}
      {pendingAction && (
        <Button
          size="sm"
          onClick={completePendingAction}
          iconName="ArrowRight"
          isLoading={case_.isPending}
        >
          {pendingAction}
        </Button>
      )}
      <p className="case-updated-date">
        Updated{" "}
        {moment().diff(moment(case_.data.updatedAt), "hours") < 24
          ? moment(case_.data.updatedAt).fromNow()
          : moment(case_.data.updatedAt).format("MMM Do, YYYY")}
      </p>
    </Card>
  );
};

export default Case;
