import * as yup from "yup";
import zipState from "zip-state";

import { Address } from "@store/../@types/user";

export const addressInitializerObject: Address = {
  street: "",
  secondary: "",
  city: "",
  state: "",
  postalCode: "",
  country: "us",
  default: false,
};

export const addressSchema = yup.object().shape({
  street: yup
    .string()
    .required("We need your street address")
    .min(5, "Must contain a valid street address")
    .max(35, "Street line has a limit of 35 characters"),
  secondary: yup.string().max(35, "Street line 2 has a limit of 35 characters"),
  city: yup
    .string()
    .required("Please enter the city of this address")
    .min(2, "Must contain at least 2 characters"),
  state: yup
    .string()
    .required("Please enter the state of this address")
    .min(2, "Please select a valid state"),
  postalCode: yup
    .string()
    .required("Please enter the zip code")
    .min(5, "A valid zipcode length should contain a min of 5 digits")
    .max(9, "A valid zipcode length should contain a max of 9 digits")
    .test("isValidZipForState", "Zip code is not valid for the selected state", function (value) {
      const { state } = this.parent;

      if (!state || !value) return true;

      const zipCodeState = zipState(value);

      if (state !== zipCodeState) {
        return false;
      }

      return true;
    })
    .required(),
});
