import { BillingDetails, Card, CardBrands, CardFunding } from "@store/../@types/billing";
import { PaymentMethod } from "@stripe/stripe-js";

export class PaymentMethodDTO {
  public id: string;
  public billingDetails: BillingDetails;
  public card: Card;

  constructor(stripePaymentMethod: PaymentMethod) {
    this.id = stripePaymentMethod.id;
    this.billingDetails = {
      address: {
        street: stripePaymentMethod.billing_details.address?.line1 || "",
        secondary: stripePaymentMethod.billing_details.address?.line2 || "",
        city: stripePaymentMethod?.billing_details.address?.city || "",
        postalCode: stripePaymentMethod?.billing_details.address?.postal_code || "",
        state: stripePaymentMethod?.billing_details.address?.state || "",
        country: "us",
        default: false,
      },
      name: stripePaymentMethod?.billing_details.name || "",
    };
    this.card = {
      brand: (stripePaymentMethod?.card?.brand as CardBrands) || "",
      expMonth: stripePaymentMethod?.card?.exp_month || 1,
      expYear: stripePaymentMethod?.card?.exp_year || 1,
      funding: (stripePaymentMethod?.card?.funding as CardFunding) || CardFunding.DEBIT,
      last4: stripePaymentMethod?.card?.last4 || "",
    };
  }
}
