import { admin, user, search, order, cases } from "../../api";
import { call, put, takeEvery } from "redux-saga/effects";
import { toastSuccess, toastError } from "@utils/utils";

import { errorHandler } from "../ui/uiSlice";
import {
  SEARCH_PARTNERS,
  GET_ORDERS,
  CANCEL_ORDER,
  GET_CASES,
  GET_CASES_BY_USER,
  GENERATE_PARTNER_CREDENTIALS,
  SEARCH_TRANSACTIONS,
  DELETE_PARTNER,
  RESUBMIT_ORDER,
  GET_PROMO_CODE,
  GET_REDEMPTIONS,
  UPDATE_CASE_SHIPPING,
  UPDATE_ORDER_SHIPPING_METHOD,
  GET_STALE_ORDERS,
  SYNC_STALE_ORDER,
  CREATE_USER_ADDRESS,
  SEND_ADMIN_ORDER,
  SEARCH_USER_BY_EMAIL,
  SEARCH_USER_BY_ID,
} from "./adminAction";
import * as adminSlice from "./adminSlice";
import { selectState } from "../../@types/state";

function* getUserByEmail(action) {
  try {
    const { data } = yield call(() => user.userGet(`search?email=${action.payload.email}`));
    yield put(adminSlice.findUser(data));
  } catch (error) {
    yield put(errorHandler("Could not get user"));
  }
}

function* getUserById(action) {
  try {
    const { data } = yield call(() => user.userGet(`search?id=${action.payload.id}`));
    yield put(adminSlice.findUser(data));
  } catch (error) {
    yield put(errorHandler("Could not get user"));
  }
}

function* createUserAddress(action) {
  try {
    const foundUser = yield selectState((state) => state.admin.users);
    const { data } = yield call(() => user.userPost("address", { address: action.payload }));
    const addresses = foundUser.addresses.push(data);
    yield put(adminSlice.findUser({ ...foundUser, addresses }));
  } catch (error) {
    yield put(errorHandler("Could not create address"));
  }
}

function* getOrders(action) {
  try {
    const { data } = yield call(() => admin.adminGet("orders", { params: action.payload }));
    yield put(adminSlice.getOrders(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get order details"));
  }
}

function* getCases(action) {
  try {
    const { data } = yield call(() => search("case", { params: action.payload }));
    yield put(adminSlice.getCases(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get order details"));
  }
}

function* getPromoCode(action) {
  try {
    const { data } = yield call(() => admin.adminGet("promocode", { params: action.payload }));
    yield put(adminSlice.getPromoCode(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get promo code"));
  }
}

function* getRedemptions(action) {
  try {
    const { data } = yield call(() =>
      admin.adminGet(`promocode/${action.payload.promoCodeId}/redemptions`)
    );
    yield put(adminSlice.getRedemptions(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get redemptions of this promo code"));
  }
}

function* getCasesByUser(action) {
  try {
    const { data } = yield call(() => user.userGet(`${action.payload}/cases`));
    yield put(adminSlice.getCases(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get order details"));
  }
}

function* getCredentials(action) {
  try {
    const result = yield call(() =>
      admin.partnerPost("", {
        name: action.payload,
      })
    );
    yield put(adminSlice.getCredentials(result.data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not onboard partner."));
  }
}

function* searchTransactions(action) {
  try {
    const result = yield call(() =>
      admin.partnerGet(`/transactions/search?partnerKey=${action.payload}`)
    );
    yield put(adminSlice.getTransactions(result.data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get transactions."));
  }
}

function* searchPartners() {
  try {
    const result = yield call(() => admin.partnerGet("s/search"));
    yield put(adminSlice.getPartners(result.data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not search partners"));
  }
}

function* deletePartner(action) {
  try {
    yield call(() => admin.partnerDelete(`/${action.payload}`));
    yield put(adminSlice.removePartner(action.payload));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not delete partner"));
  }
}

function* cancelOrder(action) {
  yield put(adminSlice.cancelOrderError(false));
  try {
    const { data } = yield call(() => order.orderPut(`/${action.payload.orderId}/cancel`));
    yield put(adminSlice.getOrders([data]));
  } catch (error) {
    console.log(error);
    yield put(adminSlice.cancelOrderError(true));
    yield put(errorHandler("Could not cancel order"));
  }
}

function* resubmitOrder(action) {
  yield put(adminSlice.resubmitOrderError(false));
  try {
    const { data } = yield call(() => order.orderPut(`/${action.payload.referenceId}`));
    yield put(adminSlice.getOrders([data]));
  } catch (error) {
    console.log(error);
    yield put(adminSlice.resubmitOrderError(true));
    yield put(errorHandler("Could not cancel order"));
  }
}

function* updateCaseShipping(action) {
  yield put(adminSlice.caseShippingError(false));
  try {
    yield call(() =>
      cases.casePut(`/${action.payload.caseId}`, {
        shipping_method: action.payload.shipping_method,
      })
    );

    if (action.payload?.orderId) {
      yield call(updateOrderShippingMethod, {
        payload: {
          orderId: action.payload.orderId,
          referenceId: action.payload.referenceId,
          shippingMethod: action.payload.shipping_method,
        },
      });
    }

    yield call(getCases, {});
  } catch (error) {
    console.log(error);
    yield put(adminSlice.caseShippingError(true));
    yield put(errorHandler("Could not update case shipping"));
  }
}

function* sendAdminOrder(action) {
  try {
    // Get order prescriptions and send the order back with the update.
    const { data: currentPrescriptions } = yield call(() =>
      order.orderGet(`/${action.payload?.orderId}/prescriptions`)
    );
    yield call(() =>
      order.orderPut(`/${action.payload?.referenceId}/send`, {
        prescriptions: currentPrescriptions,
      })
    );
    toastSuccess("Order has been re-sent.");
  } catch (err) {
    toastError("Could not send order.");
  }
}

function* updateOrderShippingMethod(action) {
  yield put(adminSlice.orderShippingError(false));
  try {
    const { data } = yield call(() =>
      order.orderPut(`/${action.payload?.referenceId}`, {
        shipping_method: action.payload?.shippingMethod,
      })
    );
    yield put(adminSlice.getOrders([data]));
    yield call(sendAdminOrder, {
      payload: {
        orderId: action.payload?.orderId,
        referenceId: action.payload?.referenceId,
      },
    });
  } catch (error) {
    console.log(error);
    yield put(adminSlice.orderShippingError(true));
    yield put(errorHandler("Could not update order shipping method"));
  }
}

function* getStaleOrders() {
  try {
    const { data } = yield call(() => order.orderGet("/stale"));
    yield put(adminSlice.getOrders(data));
  } catch (error) {
    console.log(error);
    yield put(errorHandler("Could not get stale orders"));
  }
}

function* syncStaleOrder(action) {
  try {
    const orders = yield selectState((state) => state.admin.orders);
    yield call(() => order.orderGet(`/${action.payload?.orderId}/sync`));

    let syncedOrder = orders.filter((order) => order.order_id === action.payload.orderId)[0];
    const updatedOrders = orders.filter((order) => order.order_id !== syncedOrder.order_id);

    yield put(
      adminSlice.getOrders([
        ...updatedOrders,
        { ...syncedOrder, status: syncedOrder.externalStatus },
      ])
    );
  } catch (error) {
    console.log(error);
    yield put(adminSlice.orderSyncError(true));
    yield put(errorHandler("Could not sync order"));
  }
}

export default function* adminSaga() {
  yield takeEvery(SEARCH_USER_BY_EMAIL, getUserByEmail);
  yield takeEvery(SEARCH_USER_BY_ID, getUserById);
  yield takeEvery(CREATE_USER_ADDRESS, createUserAddress);
  yield takeEvery(GET_ORDERS, getOrders);
  yield takeEvery(GET_CASES, getCases);
  yield takeEvery(GET_CASES_BY_USER, getCasesByUser);
  yield takeEvery(CANCEL_ORDER, cancelOrder);
  yield takeEvery(RESUBMIT_ORDER, resubmitOrder);
  yield takeEvery(GENERATE_PARTNER_CREDENTIALS, getCredentials);
  yield takeEvery(SEARCH_PARTNERS, searchPartners);
  yield takeEvery(SEARCH_TRANSACTIONS, searchTransactions);
  yield takeEvery(DELETE_PARTNER, deletePartner);
  yield takeEvery(GET_PROMO_CODE, getPromoCode);
  yield takeEvery(GET_REDEMPTIONS, getRedemptions);
  yield takeEvery(UPDATE_CASE_SHIPPING, updateCaseShipping);
  yield takeEvery(UPDATE_ORDER_SHIPPING_METHOD, updateOrderShippingMethod);
  yield takeEvery(GET_STALE_ORDERS, getStaleOrders);
  yield takeEvery(SYNC_STALE_ORDER, syncStaleOrder);
  yield takeEvery(SEND_ADMIN_ORDER, sendAdminOrder);
}
