import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useLoadOnTop } from "@hooks/useLoadOnTop";
import { useTitle } from "@hooks/useTitle";
import {
  Button,
  FormGroup,
  Label,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";

const AltitudeDays = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [willAscent, setWillAscent] = useState(false);
  const [ascentDescription, setAscentDescription] = useState("");

  // Define the current question and reason
  const currentQuestion = "Are you planning a specific trek or ascent on your travels?";
  const currentReason = "We ask this to inform your care team.";
  const currentPlaceholder = "Tell us about your upcoming trek.";

  useTitle("Ascent planning");
  useLoadOnTop();

  const setAnswer = () => {
    if (ascentDescription.length > 0) {
      dispatch(
        questionsUpdate({
          question: currentQuestion,
          value: ascentDescription,
          important: true,
          questionType: "altitude",
        })
      );
      history.push("/mountain_sickness");
    }
  };

  const setNo = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value: value,
        important: false,
        questionType: "altitude",
      })
    );
    history.push("/mountain_sickness");
  };

  const YesAnswer = () => {
    setWillAscent(true);
  };

  const setDescription = (e: any) => {
    setAscentDescription(e.target.value);
  };

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  return (
    <>
      <Header progress={65} />
      <PageConsultation className="rwa-page-any-problems" tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <YesNoQuestion
            setAnswer={YesAnswer}
            setNo={setNo}
            problems={true}
            isActive={willAscent}
          />

          {willAscent && (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <FormGroup className="rwa-page-any-problems-form-group">
                  <Label>Please provide details</Label>
                  <TextField
                    as="textarea"
                    value={ascentDescription}
                    onChange={setDescription}
                    placeholder={currentPlaceholder}
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </PageConsultation.Body>

        {willAscent && (
          <Button
            size="lg"
            iconName="ArrowRight"
            customWidth={440}
            onClick={setAnswer}
            disabled={ascentDescription.trim().length === 0}
          >
            Next
          </Button>
        )}
      </PageConsultation>
    </>
  );
};

export default AltitudeDays;
