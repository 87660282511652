import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

const AltitudeSickness = () => {
  const dispatch = useDispatch();
  useTitle("Altitude Sickness");

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.ALTITUDE_CONSULTATION);
  }, []);

  const history = useHistory();

  // Define the current question and reason
  const currentQuestion = "Will you be traveling at altitude above 8,000 feet?";
  const currentReason = "We ask this to inform your care.";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType: "altitude",
        important: value === "No",
      })
    );
    history.push("/ascent");
  };
  return (
    <>
      <Header progress={5} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Will you be traveling at altitude <strong>(above 8,000 feet)?</strong>
          </Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default AltitudeSickness;
