import styled from "styled-components";

import { InputBase, InputLabel, Select } from "@material-ui/core";
import { border, color, font, lineHeight, spacing } from "@runwayhealth/runway-components-react";

export const CustomInputBase = styled(InputBase)`
  &:after {
    display: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StateSelectPlaceholder = styled(InputLabel)`
  font-size: 16px !important;
  font-family: Gustavo !important;
  opacity: 60% !important;
  padding-left: 15px !important;
  padding-top: 4px !important;
`;

export const StateSelectField = styled(Select)`
  background-clip: padding-box;
  border: 1px solid ${color.neutral.light};
  border-radius: 4px;
  color: ${color.neutral.darkest};
  display: block;
  font-family: ${font.family.highlight.regular};
  font-weight: ${font.weight.regular};
  line-height: ${lineHeight.xl};
  margin-top: 12px !important;
  transition: 300ms;
  width: 100%;
  padding: ${spacing.tiny};
  letter-spacing: 0.02rem;
  height: 50px;
  outline: ${color.neutral.dark};

  // Placeholder
  &::placeholder {
    color: ${color.neutral.medium};
    opacity: 1;
  }

  // hover
  &:hover {
    border-color: ${color.brand.primary.medium};
  }
  // focus
  &:focus {
    border-color: ${color.neutral.dark};
  }

  &::-ms-expand {
    background-color: transparent;
    border: ${border.width.none};
  }

  &:-moz-focusring {
    text-shadow: 0 0 0 ${color.neutral.darkest};
  }
`;
