import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import ModalWindow from "@elements/ModalWindow";
import { yupResolver } from "@hookform/resolvers/yup";
import useAmplitude from "@hooks/useAmplitude";
import { useTitle } from "@hooks/useTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    Button, Feedback, FormGroup, FormGroupMessage, Label, Link, Text, TextField, Title
} from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";
import { DateFieldValue, RootEntity, RootState } from "@store/../@types/state";
import { FINISH_CONSULTATION } from "@store/cases/casesActions";
import { questionsUpdate } from "@store/questions/questionsSlice";

import { Case } from "../../../@types/case";
import { Condition } from "../../../@types/condition";
import { User } from "../../../@types/user";
import { validatePhone } from "../../../utils/utilsFormvalidation";
import DocsModal from "../../elements/DocsModal";
import ShortInput from "../../elements/ShortInput";
import { ParentalConsentBody } from "./ParentalConsentBody";
import { ParentalConsentFooter } from "./ParentalConsentFooter";

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  parentalConsent: boolean;
  terms: boolean;
  consent: boolean;
}

const schema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("This field is required")
      .min(2, "Must contain at least 2 characters"),
    lastName: yup
      .string()
      .required("This field is required")
      .min(2, "Must contain at least 2 characters"),
    email: yup.string().email("Please enter a valid email").required("This field is required"),
    phone: yup
      .string()
      .test({
        test(value, ctx) {
          const phoneNumberValue = value || "";

          // Format phone number.
          if (/^\d{10}$/.test(phoneNumberValue)) {
            const formattedNumber = `(${phoneNumberValue.slice(0, 3)}) ${phoneNumberValue.slice(
              3,
              6
            )}-${phoneNumberValue.slice(6)}`;
            value = formattedNumber;
          } else {
            value = phoneNumberValue; // Update input with only numeric value (for invalid input)
          }

          // Validate the area code.
          if (!validatePhone(value || "")) {
            return ctx.createError({
              message: "Please enter a valid US phone number",
            });
          }

          return true;
        },
      })
      .min(10, "Must contain minimum 10 characters")
      .max(18, "Must contain maximum 18 characters")
      .required("This field is required"),
    parentalConsent: yup.boolean().oneOf([true], "This field is required"),
    terms: yup.boolean().oneOf([true], "This field is required"),
    consent: yup.boolean().oneOf([true], "This field is required"),
  })
  .required();

const defaultValues: IFormInputs = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  parentalConsent: false,
  terms: false,
  consent: false,
};

const inputsShort = [
  { placeholder: "M", name: "field-1-parental", id: "parental-1", position: 1 },
  { placeholder: "M", name: "field-2-parental", id: "parental-2", position: 2 },
  { placeholder: "D", name: "field-3-parental", id: "parental-3", position: 3 },
  { placeholder: "D", name: "field-4-parental", id: "parental-4", position: 4 },
  { placeholder: "Y", name: "field-5-parental", id: "parental-5", position: 5 },
  { placeholder: "Y", name: "field-6-parental", id: "parental-6", position: 6 },
  { placeholder: "Y", name: "field-7-parental", id: "parental-7", position: 7 },
  { placeholder: "Y", name: "field-8-parental", id: "parental-8", position: 8 },
];

const ParentalConsent = () => {
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState<DateFieldValue>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
  });
  const [showLegal, setShowLegal] = useState(false);
  const [legalName, setLegalName] = useState("");
  const [birthday, setBirthday] = useState<any>(undefined);
  const [isDisabled, setIsDisabled] = useState(false);
  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);
  const user = useSelector<RootState, User>((state) => state.user.data);

  useTitle("Parental Consent");
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const dispatch = useDispatch();
  const {
    data: { driver_license_id },
  } = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const conditions = useSelector<RootState, Condition[]>(
    (state) => state.case.newCase.data.conditions
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (birthday === undefined) {
      return;
    }

    if (!driver_license_id) {
      return;
    }

    dispatch(
      questionsUpdate({
        question: "Legal guardian full name",
        value: `${data.firstName} ${data.lastName}`,
        important: true,
        questionType: "general",
      })
    );

    dispatch(
      questionsUpdate({
        question: "Legal guardian email",
        value: data.email,
        important: true,
        questionType: "general",
      })
    );

    dispatch(
      questionsUpdate({
        question: "Legal guardian phone",
        value: data.phone,
        important: true,
        questionType: "general",
      })
    );

    dispatch(
      questionsUpdate({
        question: "Legal guardian date of birth",
        value: birthday,
        important: true,
        questionType: "general",
      })
    );

    dispatch(
      questionsUpdate({
        question: "Parental Consent",
        value: "Parental Consent confirmed",
        important: true,
        questionType: "general",
      })
    );

    const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
    // If new case is Plus, then push plus details question.
    if (consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? conditions.length === 0) {
      history.push("/plus_details");
    } else {
      // Else finish consultation.
      dispatch({
        type: FINISH_CONSULTATION,
        newCase: newCase,
        userId: user.id !== "" ? user.id : undefined,
      });
    }
  };

  const validateDate = Object.values(date).every((el) => !!el === true);

  useEffect(() => {
    setIsDisabled(validateDate);
  }, [date]);

  const dateIsValid = () => {
    const currentYear = new Date().getFullYear();
    const objToArr = Object.values(date);

    let month = +objToArr.join("").substr(0, 2);
    let day = +objToArr.join("").substr(2, 2);
    let year = +objToArr.join("").substr(4);

    const birth = `${year}-${month}-${day}`;

    if (objToArr.length !== 8 || objToArr.some((item) => item === "")) {
      setError("Please fill all fields");
      return false;
    }

    if (currentYear - year < 18) {
      setBirthday("");
      setError("You must be at least 18 years old");
      return false;
    }

    if (
      currentYear < year ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31 ||
      currentYear - year > 122
    ) {
      setBirthday("");
      setError("Please enter a valid date");
      return false;
    }

    setBirthday(dayjs(birth));
    return true;
  };

  const handlePhotoUpload = () => {
    logEvent("ID_MODAL_OPEN");
    setModal(true);
  };

  useEffect(() => {
    logEvent("ID_START");
  }, []);

  const handleOpener = (e: any) => {
    e.preventDefault();
    setShowLegal(true);
    setLegalName(e.currentTarget.id);
  };

  const onKeyUpHandler = (e: any) => {
    dateIsValid();
  };

  return (
    <>
      <Header progress={4} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">Parental Consent</Title>
          <Text size="md">
            The clinician requires parental consent to review your consultation. These details
            should be filled out by your legal guardian.
          </Text>
        </PageConsultation.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <PageConsultation.Body>
            <ParentalConsentBody>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="firstName">Legal guardian's first name</Label>
                    <TextField
                      id="firstName"
                      placeholder="First"
                      isInvalid={errors.firstName}
                      {...register("firstName")}
                    />
                    <FormGroupMessage>
                      {errors.firstName && (
                        <Feedback size="sm" isInvalid>
                          {errors.firstName?.message}
                        </Feedback>
                      )}
                    </FormGroupMessage>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="lastName">Legal guardian's last name</Label>
                    <TextField
                      id="lastName"
                      placeholder="Last"
                      isInvalid={errors.lastName}
                      {...register("lastName")}
                    />
                    <FormGroupMessage>
                      {errors.lastName && (
                        <Feedback size="sm" isInvalid>
                          {errors.lastName?.message}
                        </Feedback>
                      )}
                    </FormGroupMessage>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="email">Legal guardian's email</Label>
                    <TextField
                      id="email"
                      placeholder="Email"
                      isInvalid={errors.email}
                      {...register("email")}
                    />
                    <FormGroupMessage>
                      {errors.email && (
                        <Feedback size="sm" isInvalid>
                          {errors.email?.message}
                        </Feedback>
                      )}
                    </FormGroupMessage>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="phone">Legal guardian's phone</Label>
                    <TextField
                      id="phone"
                      placeholder="(999) 999-9999"
                      isInvalid={errors.phone}
                      {...register("phone")}
                    />
                    <FormGroupMessage>
                      {errors.phone && (
                        <Feedback size="sm" isInvalid>
                          {errors.phone?.message}
                        </Feedback>
                      )}
                    </FormGroupMessage>
                  </FormGroup>
                </div>
              </div>

              <FormGroup className="input-birthday">
                <Label htmlFor="firstName">Legal guardian's date of birth</Label>
                <div className="date-inputs">
                  <div className="short-inputs-container">
                    {inputsShort.map((item, index) => (
                      <div
                        key={`date-input-${index}`}
                        className={"date-input-map"}
                        onKeyUp={onKeyUpHandler}
                        onBlur={onKeyUpHandler}
                      >
                        <ShortInput
                          id={item.id}
                          position={item.position}
                          placeholder={item.placeholder}
                          date={date}
                          setDate={setDate}
                          setError={setError}
                        />
                        {item.position === 2 || item.position === 4 ? "-" : null}
                      </div>
                    ))}
                  </div>
                  <FormGroupMessage>
                    {error && (
                      <Feedback size="sm" isInvalid>
                        {error}
                      </Feedback>
                    )}
                  </FormGroupMessage>
                </div>
              </FormGroup>

              <FormGroup className="upload-image">
                {driver_license_id ? (
                  <Feedback isValid size="md">
                    Photo uploaded successfully.{" "}
                    <Button type="button" size="sm" variant="outline" onClick={handlePhotoUpload}>
                      Upload again
                    </Button>
                  </Feedback>
                ) : (
                  <>
                    <Label>Submit a photo of your legal guardian’s ID</Label>
                    <Button
                      type="button"
                      size="md"
                      variant="outline"
                      iconName="Paperclip"
                      onClick={handlePhotoUpload}
                      isIconOnly
                    />
                  </>
                )}
              </FormGroup>

              <div className="legal-options">
                <FormControlLabel
                  control={
                    <Controller
                      name="parentalConsent"
                      control={control}
                      render={({ field }) => (
                        <Checkbox color="default" checked={field.value} onChange={field.onChange} />
                      )}
                    />
                  }
                  label={
                    <Text as="span" size="sm" noMarginBottom>
                      I confirm that I have either directly provided this medical history or been
                      present to review my child's health information for this telehealth visit.
                    </Text>
                  }
                />
                <FormGroupMessage className="rw-checkbox-feedback">
                  {errors.parentalConsent && (
                    <Feedback size="sm" isInvalid>
                      {errors.parentalConsent?.message}
                    </Feedback>
                  )}
                </FormGroupMessage>

                <FormControlLabel
                  control={
                    <Controller
                      name="terms"
                      control={control}
                      render={({ field }) => (
                        <Checkbox color="default" checked={field.value} onChange={field.onChange} />
                      )}
                    />
                  }
                  label={
                    <Text as="span" size="sm" noMarginBottom>
                      I agree to Runway's{" "}
                      <Link as="span" id="terms" onClick={(e) => handleOpener(e)}>
                        Terms and Conditions
                      </Link>
                      ,{" "}
                      <Link as="span" id="privacy" onClick={(e) => handleOpener(e)}>
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link as="span" id="information" onClick={(e) => handleOpener(e)}>
                        Information Sharing
                      </Link>
                    </Text>
                  }
                />
                <FormGroupMessage className="rw-checkbox-feedback">
                  {errors.terms && (
                    <Feedback size="sm" isInvalid>
                      {errors.terms?.message}
                    </Feedback>
                  )}
                </FormGroupMessage>

                <FormControlLabel
                  control={
                    <Controller
                      name="consent"
                      control={control}
                      render={({ field }) => (
                        <Checkbox color="default" checked={field.value} onChange={field.onChange} />
                      )}
                    />
                  }
                  label={
                    <Text as="span" size="sm">
                      I agree to Runway's{" "}
                      <Link as="span" id="consent" onClick={(e) => handleOpener(e)}>
                        Telehealth Consent
                      </Link>
                    </Text>
                  }
                />
                <FormGroupMessage className="rw-checkbox-feedback">
                  {errors.consent && (
                    <Feedback size="sm" isInvalid>
                      {errors.consent?.message}
                    </Feedback>
                  )}
                </FormGroupMessage>

                {showLegal && <DocsModal open={showLegal} close={setShowLegal} name={legalName} />}
              </div>
            </ParentalConsentBody>
          </PageConsultation.Body>

          <PageConsultation.Footer>
            <ParentalConsentFooter>
              <Button
                size="lg"
                iconName="ArrowRight"
                type="submit"
                disabled={
                  !isDirty ||
                  !isValid ||
                  !isDisabled ||
                  !birthday ||
                  (driver_license_id ? driver_license_id.length === 0 : false)
                }
              >
                Continue
              </Button>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <Text as="p" size="sm">
                    * Your data will be managed in compliance with HIPAA privacy regulations.
                  </Text>
                </div>
              </div>
            </ParentalConsentFooter>
          </PageConsultation.Footer>
        </form>
        {modal && <ModalWindow open={modal} close={setModal} next={false} minor={true} />}
      </PageConsultation>
    </>
  );
};

export default ParentalConsent;
