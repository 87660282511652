import { PaymentMethod } from "@store/../@types/billing";
import { UserSearchData } from "@store/../@types/state";
import { Address, Affiliate, Gender, User } from "@store/../@types/user";

export class UserDTO implements User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  gender?: Gender;
  phone_number: string;
  phone_type: number;
  date_of_birth: Date;
  height: number;
  weight: number;
  active: boolean;
  gender_label: string;
  current_medications: string;
  medicalConditions: string;
  allergies: string;
  "patient-id": string | null;
  pregnancy: boolean;
  addresses: Address[];
  password: string;
  confirmPassword: string;
  driver_license_id: string;
  pharmacy_id: string;
  paymentMethods: PaymentMethod[];
  defaultPaymentMethod: string;
  partnerTx?: string;
  isAdmin: boolean;
  affiliateProfile?: Affiliate;
  promocodes: Array<{
    active: boolean;
    code: string;
  }>;
  createdAt?: number;

  constructor(userSearchResult: UserSearchData) {
    this.id = userSearchResult.id;
    this.first_name = userSearchResult.firstName;
    this.last_name = userSearchResult.lastName;
    this.email = userSearchResult.email;
    this.gender = undefined; // Gender is not available in UserSearchData
    this.phone_number = userSearchResult.phoneNumer;
    this.phone_type = 0; // Default value as phone_type is not available in UserSearchData
    this.date_of_birth = new Date(userSearchResult.dob);
    this.height = 0; // Default value as height is not available in UserSearchData
    this.weight = 0; // Default value as weight is not available in UserSearchData
    this.active = true; // Default value as active status is not available in UserSearchData
    this.gender_label = ""; // Default value as gender_label is not available in UserSearchData
    this.current_medications = ""; // Default value as current_medications is not available in UserSearchData
    this.medicalConditions = ""; // Default value as medicalConditions is not available in UserSearchData
    this.allergies = ""; // Default value as allergies is not available in UserSearchData
    this["patient-id"] = null; // Default value as patient-id is not available in UserSearchData
    this.pregnancy = false; // Default value as pregnancy is not available in UserSearchData
    this.addresses = userSearchResult.addresses;
    this.password = ""; // Default value as password is not available in UserSearchData
    this.confirmPassword = ""; // Default value as confirmPassword is not available in UserSearchData
    this.driver_license_id = ""; // Default value as driver_license_id is not available in UserSearchData
    this.pharmacy_id = ""; // Default value as pharmacy_id is not available in UserSearchData
    this.paymentMethods = []; // Default value as paymentMethods is not available in UserSearchData
    this.defaultPaymentMethod = ""; // Default value as defaultPaymentMethod is not available in UserSearchData
    this.partnerTx = undefined; // Default value as partnerTx is not available in UserSearchData
    this.isAdmin = false; // Default value as isAdmin is not available in UserSearchData
    this.affiliateProfile = undefined; // Default value as affiliateProfile is not available in UserSearchData
    this.promocodes = []; // Default value as promocodes is not available in UserSearchData
    this.createdAt = undefined; // Default value as createdAt is not available in UserSearchData
  }
}
