import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_MESSAGES, CREATE_MESSAGE, GET_MESSAGES_LAST_PAGE } from "./messagesAction";
import { md, user } from "../../api";
import { pushMessages, setLastPage, sendMessageSuccess, setMessagesPending } from "./messagesSlice";
import { toastError } from "@utils/utils";
import { UserType } from "../../@types/mdi";

function* createMessage(action) {
  const { text, uploadedFile } = action.payload;

  try {
    // Data to show in front-end.
    const messageBody = {
      user_type: UserType.PATIENT,
      text: text,
      files: uploadedFile ? [uploadedFile] : undefined,
      isPending: true,
    };
    yield put(pushMessages(messageBody));

    const message = yield call(() =>
      user.userPost("message", {
        message: {
          channel: "patient",
          text: action.payload.text || "file",
          files: uploadedFile
            ? [
                {
                  ...uploadedFile,
                  id: uploadedFile.file_id,
                },
              ]
            : [],
        },
      })
    );
    yield put(sendMessageSuccess(message.data.id));
  } catch (error) {
    toastError("Error.", "Could not send message", error);
  }
}

function* getMessages(action) {
  try {
    yield put(setMessagesPending());
    const { data } = yield call(() => user.userGet(`messages?page=${action.payload.page}`));
    yield put(pushMessages(data));
  } catch (error) {
    console.log(error.response);
  }
}

function* getMessagesLastPage(action) {
  try {
    yield put(setMessagesPending());
    const { data } = yield call(() => user.userGet("messages"));
    yield put(setLastPage(data.meta.last_page));

    // Get messages from the first page.
    yield call(getMessages, { payload: { page: 1 } });
  } catch (error) {
    console.log(error.response);
  }
}

export default function* messageSaga() {
  yield takeEvery(GET_MESSAGES, getMessages);
  yield takeEvery(GET_MESSAGES_LAST_PAGE, getMessagesLastPage);
  yield takeLatest(CREATE_MESSAGE, createMessage);
}
