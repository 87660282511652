import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import WebcamCapture from "../WebcamCapture";

import photoFromComputer from "@img/photo-from-computer.png";
import photoFromWebCam from "@img/photo-from-webcam.png";
import useAmplitude from "@hooks/useAmplitude";

import "./ModalWindow.scss";
import PreviewFile from "../previewFileModal/previewFile";
import {
  Button,
  Text,
  color,
  spacing,
  border,
  opacity,
} from "@runwayhealth/runway-components-react";
import { X } from "phosphor-react";

const ModalWindow = ({ open, close, next, minor }) => {
  const [cam, setCam] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const userId = useSelector((state) => state.user.data.id);

  //UPLOAD FILE
  const [selectedFile, setSelectedFile] = useState();
  const [base64Img, setBase64Img] = useState();
  const { logEvent } = useAmplitude();

  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      backgroundColor: `${color.neutral.lightest}`,
      borderRadius: screenWidth < 768 ? `${border.radius.lg}` : `${border.radius.xl}`,
      height: "auto",
      margin: `${spacing.nano}`,
      width: screenWidth < 768 ? "100%" : screenWidth < 992 ? "82%" : "74%",
      maxWidth: "1200px",
      position: "relative",
      maxHeight: "100%",
      overflowY: "scroll",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [window.innerWidth]);

  const handleOpenWebcam = () => {
    logEvent("OPEN_WEBCAM");
    setCam(true);
  };

  const convertBase64 = (file) => {
    logEvent("FILE_CONVERT");
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const onSelectFile = async (e) => {
    logEvent("FILE_SELECTED");

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
    const base64img = await convertBase64(e.target.files[0]);
    setBase64Img(base64img);
  };

  const hiddenFileInput = useRef(null);

  const handleUploadPhoto = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ph-no-capture`}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: `${color.neutral.darkest}`, opacity: `${opacity.semiOpaque}` },
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {/* Upload photo */}
          {base64Img && (
            <PreviewFile
              selectedFile={selectedFile}
              uploadFile={base64Img}
              setImg={setBase64Img}
              next={next}
              minor={minor}
              close={close}
              userId={userId}
              className="ph-no-capture"
            />
          )}

          {/* Take photo */}
          {cam && (
            <WebcamCapture
              cam={setCam}
              next={next}
              uploadFile={base64Img}
              minor={minor}
              close={close}
              userId={userId}
            />
          )}

          {/* Modal */}
          {!cam && !base64Img && (
            <div className="rw-modal-upload-photo">
              <div className="rw-modal-upload-photo-close-button" onClick={() => close(false)}>
                <X size={24} weight="bold" color={`${color.neutral.darker}`} />
              </div>

              <div className="rw-modal-upload-photo-container">
                <div className="rw-modal-item-left">
                  <div>
                    <img src={photoFromComputer} alt="Upload photo" />
                    <Text>Already have an ID or Passport photo?</Text>
                  </div>

                  <Button variant="outline" customWidth={220} onClick={handleUploadPhoto}>
                    Upload ID
                  </Button>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    ref={hiddenFileInput}
                    onChange={onSelectFile}
                    style={{ display: "none" }}
                  />
                </div>

                <div className="rw-modal-item-right">
                  <div>
                    <img src={photoFromWebCam} alt="Take a photo" />
                    <Text>Take a photo of your ID or Passport</Text>
                  </div>
                  <Button variant="outline" customWidth={220} onClick={handleOpenWebcam}>
                    Capture ID
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalWindow;
