import "./adminmain.scss";

import { useLocation } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import CaseSearch from "./CaseSearch";
import OrderSearch from "./OrderSearch";
import PartnerManager from "./PartnerManager";
import PromoCodeSearch from "./PromoCodeSearch";
import UserSearch from "./UserSearch";

const AdminMain = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const promoCodeSearch = searchParams.get("promocode");
  const userEmail = searchParams.get("email");

  return (
    <div className="rw-admin-main ph-no-capture">
      <h1>Admin Tools 🦸</h1>
      <Accordion>
        <AccordionSummary>User Search 🕵️</AccordionSummary>
        <AccordionDetails>
          <UserSearch />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Case Search 📝</AccordionSummary>
        <AccordionDetails>
          <CaseSearch />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Order Search 📦</AccordionSummary>
        <AccordionDetails>
          <OrderSearch />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Partner Management 👩‍💼</AccordionSummary>
        <AccordionDetails>
          <PartnerManager />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={promoCodeSearch ? true : false}>
        <AccordionSummary>Promo Code Search 🛍️</AccordionSummary>
        <AccordionDetails>
          <PromoCodeSearch userEmail={userEmail || undefined} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AdminMain;
