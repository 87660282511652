import "./PasswordAccount.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "@runwayhealth/runway-components-react";
import { PASSWORD_UPDATE } from "@store/user/userActions";

import { PasswordUpdate } from "../../../../../../../@types/user";
import { validatePassword } from "../../../../../../../utils/utilsFormvalidation";
import TextFieldPassword from "../../../../../../elements/TextFieldPassword/TextFieldPassword";

interface PasswordProps {
  disablePasswordForm: boolean;
  error: string;
  setDisablePasswordForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordAccount = ({ setDisablePasswordForm, disablePasswordForm }: PasswordProps) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState<PasswordUpdate>({
    oldPassword: "",
    newPassword: "",
  });
  const [errorText, setErrorText] = useState("");

  const handlerUserPassword = (event: React.SyntheticEvent, name: string) => {
    const value = ((event as React.SyntheticEvent).target as HTMLInputElement).value;
    setPassword((prev) => ({ ...prev, [name]: value }));
  };

  const save = () => {
    if (formIsValid()) {
      dispatch({
        type: PASSWORD_UPDATE,
        oldPassword: password.oldPassword,
        newPassword: password.newPassword,
      });
      setDisablePasswordForm(true);
    }
  };

  const formIsValid = () => {
    if (!password.oldPassword) {
      setErrorText("Please enter your current password.");
      return false;
    }

    if (!password.newPassword) {
      setErrorText("Please enter your new password.");
      return false;
    }

    if (!validatePassword(password.newPassword)) {
      setErrorText("Please enter a more secure password.");
      return false;
    }

    return true;
  };

  const isValidPassword = (password: string) => {
    if (!validatePassword(password)) {
      setErrorText("Please enter a more secure password.");
    }
  };

  return (
    <>
      <div className="account-password-change">
        <TextFieldPassword
          title="Old password"
          onChange={(e: React.SyntheticEvent) => handlerUserPassword(e, "oldPassword")}
          onBlur={(e: React.SyntheticEvent) => {}}
        />
        <TextFieldPassword
          title="New password"
          onChange={(e: React.SyntheticEvent) => handlerUserPassword(e, "newPassword")}
          onBlur={(e: React.SyntheticEvent) =>
            isValidPassword(((e as React.SyntheticEvent).target as HTMLInputElement).value)
          }
        />
      </div>
      {!disablePasswordForm && (
        <Button size="md" iconName="ArrowRight" iconPosition="right" isBlock onClick={save}>
          Change Password
        </Button>
      )}
      {errorText && (
        <div className="error-message-forgot">
          <span className="message-content-forgot">{errorText}</span>
        </div>
      )}
    </>
  );
};
