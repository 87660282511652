import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import Header from "../../elements/Header";
import YesNoQuestion from "../../templates/YesNoQuestion";

const CDiff = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("Clostridium difficile");
  let important = false;

  // Define the current question and reason
  const currentQuestion =
    "Have you ever been hospitalized for a Clostridium difficile (C. Diff.) diarrheal infection?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value: string) => {
    if (value === "Yes") {
      important = true;
    }

    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        important,
        questionType: "diarrhea",
      })
    );
    history.push("/conditions");
  };
  return (
    <>
      <Header progress={75} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <YesNoQuestion option="" setAnswer={setAnswer} />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default CDiff;
