import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "@components/elements/Header";
import { PageConsultation } from "@components/layouts/PageConsultation";
import YesNoQuestion from "@components/templates/YesNoQuestion";
import { useTitle } from "@hooks/useTitle";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { questionsUpdate } from "@store/questions/questionsSlice";

const TripType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useTitle("What type of trip are you going for?");

  // Define the current question and reason
  const currentQuestion = "What type of trip is this?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType: "general",
      })
    );
    history.push("/travellinglong");
  };
  return (
    <div>
      <Header progress={12} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <div className="slide-buttons">
            <div className="choice-buttons">
              <button className="choice-male" onClick={() => setAnswer("Leisure")}>
                Leisure
              </button>
              <button className="choice-female" onClick={() => setAnswer("Business")}>
                Business
              </button>
              <button className="choice-other" onClick={() => setAnswer("Both")}>
                Both
              </button>
            </div>
          </div>
        </PageConsultation.Body>
      </PageConsultation>
    </div>
  );
};

export default TripType;
