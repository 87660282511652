import posthog from "posthog-js";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as ConsultationComp from "@components/layouts/StartConsultationPage";
import HelpModal from "@elements/HelpModal";
import useAmplitude from "@hooks/useAmplitude";
import { useQuery } from "@hooks/useQuery";
import { useTitle } from "@hooks/useTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Button,
  Feedback,
  FormGroup,
  FormGroupMessage,
  Label,
  TextField,
} from "@runwayhealth/runway-components-react";
import { REFRESH_CASES } from "@store/cases/casesActions";
import { clearQuestions } from "@store/questions/questionsSlice";
import { COLLECT_MARKETING_EMAIL, LOGOUT } from "@store/user/userActions";
import { userUpdate } from "@store/user/userSlice";
import { validateEmail } from "@utils/utilsFormvalidation";

import { Case } from "../../../@types/case";
import { ConditionTypes } from "../../../@types/condition";
import { ErrorEvents, RootEntity, RootState } from "../../../@types/state";
import { User } from "../../../@types/user";

// Lazy components
const Cart = lazy(() => import("@components/Cart/index"));

const StartConsultation = () => {
  useTitle("Start Consultation");
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { logEvent } = useAmplitude();
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [emailAgree, setEmailAgree] = useState(false);

  // URL Handling.
  const { itemsBatch } = useParams<{ itemsBatch: string }>();
  const decodedData = window.atob(itemsBatch || "");
  const { items, country, transaction } =
    decodedData !== ""
      ? JSON.parse(decodedData)
      : {
          items: [],
          country: "",
          transaction: "",
        };

  const [includesTyphoid] = useState<boolean>(items.includes(ConditionTypes.TYPHOID_FEVER));

  // Redux state.
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const casesState = useSelector<RootState, RootEntity<RootEntity<Case>[]>>(
    (state) => state.case.cases
  );
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);

  useEffect(() => {
    // Logs consultation start to amplitude.
    logEvent("CONSULTATION_LANDING");
    // Initializes questionnaire map.
    sessionStorage.setItem("finished_consultations", JSON.stringify([]));
    // Initializes question fields.
    dispatch(clearQuestions());

    // Save cart in cookie if is a referral.
    const runwayReferral = query.get("rwr");
    if (Boolean(runwayReferral) && runwayReferral === "true") {
      // document.cookie = `cart=${itemsBatch};`;
      // dispatch({
      //   type: LOGOUT,
      // });
    }
  }, []);

  useEffect(() => {
    /**
     * By refreshing user cases we can understand
     * if access is granted or not.
     */
    if (user.data.id) {
      dispatch({ type: REFRESH_CASES, userId: user.data.id });
    }
  }, []);

  useEffect(() => {
    // If a user comes from a transaction, we set the transaction state.
    if (transaction !== "") {
      dispatch(userUpdate({ name: "partnerTx", value: transaction }));
    }
  }, []);

  useEffect(() => {
    // If access is denied while refreshing cases.
    // Then log out expired user.
    const corruptedSession =
      casesState.error.event === ErrorEvents.ACCESS_DENIED ||
      newCase.error.event === ErrorEvents.ACCESS_DENIED ||
      newCase.error.message === "Unknown error.";
    if (corruptedSession) {
      dispatch({
        type: LOGOUT,
      });
    }
  }, [casesState.error || newCase.error]);

  const handleClick = () => {
    if (errorText.length === 0 && email.length > 0) {
      if (emailAgree) {
        dispatch({
          type: COLLECT_MARKETING_EMAIL,
          email,
        });
      }
      // Should log consultation event to amplitude.
      history.push("/state");
      posthog.capture("email_submitted", { email: email.toLowerCase() });
      // posthog.identify(email.toLowerCase()); TO DO IF POSTHOG RECOMMENDS!
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorText("");
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      return setErrorText("Please enter your email address");
    }
    dispatch(userUpdate({ name: "email", value: e.target.value }));
  };

  return (
    <ConsultationComp.StartConsultationPage
      tabIndex={1}
      onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => e.key == "Enter" && handleClick()}
    >
      <HelpModal />
      <ConsultationComp.Sidebar>
        <ConsultationComp.Logo />
      </ConsultationComp.Sidebar>
      <ConsultationComp.MainContent>
        <ConsultationComp.ContentInfo>
          {newCase.data.conditions.length === 0 ? (
            <ConsultationComp.RunwayPlusText />
          ) : (
            <ConsultationComp.RunwayRxText />
          )}
          <div className="form-content">
            <FormGroup hasValidationField>
              <Label htmlFor="label">Email</Label>
              <TextField
                id="email"
                placeholder="Your email address"
                value={email}
                onChange={handleChange}
                onBlur={handleChange}
                isInvalid={errorText !== ""}
              />
              <FormGroupMessage>
                <Feedback size="sm" isInvalid>
                  {errorText}
                </Feedback>
              </FormGroupMessage>
            </FormGroup>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={emailAgree}
                    onChange={() => setEmailAgree(!emailAgree)}
                  />
                }
                label={<ConsultationComp.ConsentText />}
              />
            </div>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <Cart items={items} country={country} includesTyphoid={includesTyphoid} />
            <Button
              size="lg"
              onClick={() => handleClick()}
              iconName="ArrowRight"
              isLoading={newCase.isPending}
              disabled={errorText.length > 0 || email === ""}
              isBlock
            >
              Start my visit
            </Button>
            <ConsultationComp.MemberLogin
              onClick={() =>
                history.push({ pathname: "/login", state: { prevPath: location.pathname } })
              }
            />
          </Suspense>
        </ConsultationComp.ContentInfo>
      </ConsultationComp.MainContent>
    </ConsultationComp.StartConsultationPage>
  );
};

export default StartConsultation;
