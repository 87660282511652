import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import RadioButton from "@elements/RadioButton";
import RadioButtonInput from "@elements/RadioButtonInput";
import { useIsMinor } from "@hooks/useIsMinor";
import { useTitle } from "@hooks/useTitle";
import { Button, Title } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { ConditionTypes } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import { FINISH_CONSULTATION } from "@store/cases/casesActions";
import { questionsUpdate } from "@store/questions/questionsSlice";

// Currently, this is the last question of the questionnaire.
const MarketingChannels = () => {
  const { isAMinor } = useIsMinor();
  const variants = [
    "Travel agent or supplier",
    "Friend or family member",
    "Google",
    "Bing",
    "Social Media",
  ];
  const [state, setState] = useState([false, false, false, false, false, false]);
  const user = useSelector<RootState, User>((state) => state.user.data);
  const newCase = useSelector<RootState, Case>((state) => state.case.newCase.data);

  const dispatch = useDispatch();
  const history = useHistory();

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    e.key == "Enter" && setAnswer();
  };

  const capturedVariant = state
    .map((item, i) => (item ? variants[i] : false))
    .filter((variant) => variant)
    .join("");

  const setAnswer = async () => {
    if (capturedVariant || customValue !== "") {
      dispatch(
        questionsUpdate({
          question: "How did you hear about Runway?",
          value: capturedVariant || customValue,
          questionType: "general",
          important: false,
        })
      );
      if (isAMinor(user.date_of_birth)) {
        history.push("/parental_consent");
      } else {
        // Use always in the last question.
        // We will be skipping ID question
        // const confirmIdRoute = await useConfirmIdRoute(user.data.driver_license_id, user.data.id);
        // history.push(confirmIdRoute);

        // If new case is Plus, then push plus details question.
        const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
        if (consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? newCase.conditions.length === 0) {
          history.push("/plus_details");
        } else {
          // Else finish consultation.
          dispatch({
            type: FINISH_CONSULTATION,
            newCase: newCase,
            userId: user.id !== "" ? user.id : undefined,
          });
        }
      }
    }
  };

  const [customValue, setCustomValue] = useState("");
  const handleChange = (e: any) => setCustomValue(e.target.value);

  useTitle("How did you hear about Runway?");

  return (
    <>
      <Header progress={10} />
      <PageConsultation tabIndex={1} onKeyUp={onKeyUpHandler}>
        <PageConsultation.Header>
          <Title size="giant">How did you hear about Runway?</Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="rw-page-consultation-list-checkboxes">
            {variants.map((variant, index) => (
              <RadioButton
                key={`option-${index}`}
                content={variant}
                checked={state[index]}
                setChecked={setState}
                num={index}
              />
            ))}
            <RadioButtonInput
              key={`option-${5}`}
              placeholder={"Somewhere else"}
              checked={state[5]}
              setChecked={setState}
              setValue={handleChange}
              value={customValue}
              num={5}
            />
          </div>
        </PageConsultation.Body>

        <Button
          size="lg"
          iconName="ArrowRight"
          customWidth={440}
          onClick={setAnswer}
          disabled={!capturedVariant && customValue === ""}
        >
          Next
        </Button>
      </PageConsultation>
    </>
  );
};

export default MarketingChannels;
