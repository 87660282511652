import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageConsultation } from "@components/layouts/PageConsultation";
import { useTitle } from "@hooks/useTitle";
import { Text, Title } from "@runwayhealth/runway-components-react";
import { ConditionTypes } from "@store/../@types/condition";

import { questionsUpdate } from "../../../store/questions/questionsSlice";
import ChoiceButtons from "../../elements/ChoiceButtons";
import Header from "../../elements/Header";

const NitrofurantoinPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem("current_consultation", ConditionTypes.URINARY_TRACT_INFECTION);
  }, []);
  useTitle(ConditionTypes.URINARY_TRACT_INFECTION);

  const history = useHistory();

  // Define the current question and reason
  const currentQuestion =
    "Have you successfully used nitrofurantoin (macrobid) to treat a urinary tract infection in the past?";
  const currentReason = "We ask this to inform your care team.";

  const setAnswer = (value: string) => {
    dispatch(
      questionsUpdate({
        question: currentQuestion,
        value,
        questionType: "urinaryTractInfection",
      })
    );

    history.push("/immunocompromised");
  };

  return (
    <>
      <Header progress={2.5} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">{currentQuestion}</Title>
          <Text size="md">{currentReason}</Text>
        </PageConsultation.Header>
        <PageConsultation.Body>
          <ChoiceButtons
            first="Yes"
            second="No"
            setAnswer={setAnswer}
            setNo={() => {}}
            problems={false}
            isActive={false}
            isActiveSecond={false}
          />
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default NitrofurantoinPage;
